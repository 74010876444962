import React,{Component} from 'react';
import {removeStaff, createStaff, getSingleStaff, updateStaff} from '../functions/Api';
import {DefaultHeaderBar} from '../components/Header_bar';
import {StaffForm} from '../components/Forms';
import {Redirect} from 'react-router-dom';
import {getPermissions} from '../functions/Api';
import { findWithAttr} from '../functions/Global';

class StaffEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            redirect: false,
            perms: null
        }

        if(this.props.match.params.id !== "create_new") {
            getSingleStaff(this.props.match.params.id, (res) => {
                console.log(res);
                this.setState ({
                    data: res
                })
            });
        }

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        })
    }

    handleSave = () => {
        this.refs.staff_form.handleSave();
    }

    saveStaff = (data) => {
        if(this.props.match.params.id !== "create_new") {
            let new_data = data;

            data["date_created"] = this.state.data.date_created;
            
            updateStaff(data, this.state.data._id, (res) => {
                this.setState ({
                    redirect: true
                })
            });
        }else {
            createStaff(data, (res) => {
                this.setState ({
                    redirect: true
                })
            });
        }
    }

    handleRemove = () => {
        removeStaff(this.props.match.params.id, (res) => {
            this.setState ({
                redirect: true
            })
        });
    }

    render() {
        if(!this.state.data && this.props.match.params.id !== "create_new" || !this.state.perms) {
            return(<div></div>)
        }

        if(this.state.redirect){
            return(<Redirect to="/staff"/>)
        }

        return(
            <div className="view">
                {this.props.match.params.id !== "create_new" ? <DefaultHeaderBar create_btn_link={null}  onGoBack={() => this.props.history.goBack()}  handleRemove={this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.staff[3] == "1" ? this.handleRemove : null } title="Nhân Viên" handleSave={this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.staff[2] == "1" ? this.handleSave: null}/> : <DefaultHeaderBar  onGoBack={() => this.props.history.goBack()}  title="Nhân Viên" handleSave={this.handleSave}/>}
                <StaffForm ref="staff_form" data={this.state.data} onSave={this.saveStaff}/>
            </div>
        )
    }
}

export default StaffEdit;