import React, {Component} from 'react';
import {getGoiChup, getPermissions, getStaff, getBranch, getStatus, getBill, getServices, updateSalaryHistory} from '../functions/Api';
import {LavenDatePicker} from '../components/DatePicker';
import {salaryLoad, salaryCalculate, getAge} from '../functions/Global';
import {SalaryTableCard} from './Cards';
import {numberWithCommas, removeVietnameseTones, findWithAttr} from '../functions/Global';
import {Redirect} from 'react-router-dom';

import "../styles/components/Forms.scss";

class BillForm extends Component {
    constructor(props) {
        super(props);

        let ngay_sinh_be = this.props.data ? this.props.data.ngay_sinh_be : null;
        ngay_sinh_be = ngay_sinh_be ? ngay_sinh_be.split("-")[0].length == 2 ? ngay_sinh_be : ngay_sinh_be.split("-")[2] + '-' + ngay_sinh_be.split("-")[1] + '-' + ngay_sinh_be.split("-")[0] : null

        let ngay_chup = this.props.date ? this.props.date : this.props.data ? this.props.data.ngay_chup : null
        ngay_chup = ngay_chup ? ngay_chup.split("-")[0].length == 2 ? ngay_chup : ngay_chup.split("-")[2] + '-' + ngay_chup.split("-")[1] + '-' + ngay_chup.split("-")[0] : null

        let ngay_giao_hinh = this.props.data ? this.props.data.ngay_giao_hinh : null;
        ngay_giao_hinh = ngay_giao_hinh ? ngay_giao_hinh.split("-")[0].length == 2 ? ngay_giao_hinh : ngay_giao_hinh.split("-")[2] + '-' + ngay_giao_hinh.split("-")[1] + '-' + ngay_giao_hinh.split("-")[0] : null

        this.state = {
            goi_chup_data: null,
            staff: null,
            branch: null,
            status: null,
            date: ngay_chup,
            birthday: ngay_sinh_be,
            ngay_giao_hinh: ngay_giao_hinh,
        }

        getGoiChup((res) => {
            this.setState ({
                goi_chup_data: res
            })
        });

        getStaff((res) => {
            this.setState({
                staff: res
            })
        })

        getBranch((res) => {
            this.setState({
                branch: res
            })
        })

        getStatus((res) => {
            this.setState({
                status: res
            })
        });
    }
    
    handleSave = () => {
        if(this.refs.chi_nhanh.value !== "default") {
            let data = {
                chi_nhanh: this.refs.chi_nhanh.value,
                gio_chup: this.refs.gio_chup.value,
                ngay_chup: this.state.date ? this.state.date : new Date(),
                ghi_chu: this.refs.ghi_chu.value,
                photo: this.refs.photo.value,
                support: this.refs.support.value,
                photoshop: this.refs.photoshop.value,
                ten_khach_hang: this.refs.ten_khach_hang.value,
                dia_chi: this.refs.dia_chi.value,
                ho_ten_be: this.refs.ho_ten_be.value,
                ngay_sinh_be: this.state.birthday,
                gioi_tinh_be: this.refs.gioi_tinh_be.value,
                so_tuoi: this.refs.so_tuoi.value,
                so_dien_thoai: this.refs.so_dien_thoai.value,
                tinh_trang: this.refs.tinh_trang.value,
                ngay_giao_hinh : this.state.ngay_giao_hinh
            }

            this.props.onSave(data);
        }else{
            alert("Chưa nhập đủ thông tin khách hàng!")
        }
    }

    onTagsChange = (e, value) => {
        
    }

    handleChange = (date) => {
        this.setState({
          date: date
        });
    }

    handleChangeBirthday = (date) => {
        let new_date = date.split("-");
        new_date = new_date[0].length !== 2 ? date : new_date[2] + '-' + new_date[1] + '-' + new_date[0];

        let age = getAge(new Date(new_date));
        this.refs.so_tuoi.value = age

        this.setState({
          birthday: date
        });
    }

    handleChangeNgayGiaoHinh = (date) => {
        this.setState({
            ngay_giao_hinh: date
        });
    }

    render() {
        const chuc_vu = localStorage.getItem("chuc_vu");

        if(!this.state.goi_chup_data || !this.state.staff || !this.state.branch || !this.state.status) {
            return(<div></div>)
        }

        return(
            <div className="bill_form_container">
                <div className="column">
                    <div className="box">
                        <span className="title">Chi Nhánh:</span>
                        <select defaultValue={this.props.data ? this.props.data.chi_nhanh : "default"} ref="chi_nhanh" disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ?  null : 'disabled'}>
                            <option disabled selected value="default"></option>
                            {this.state.branch.map((data, i) => {
                                return (
                                    <option value={data._id} key={i}>{data.ten_chi_nhanh}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="box">
                        <span className="title">Giờ Chụp:</span>
                        <input className="textbox" defaultValue={this.props.data ? this.props.data.gio_chup : ""} ref="gio_chup" type="time" disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ?  null : 'disabled'}/>
                    </div>
                    <div className="box">
                        <span className="title">Ngày Chụp:</span>
                        <LavenDatePicker format={'DD-MM-YYYY'} date={this.state.date} ref="ngay_chup" className="textbox" onChange={this.handleChange} disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ?  null : 'disabled'}/>
                    </div>
                    <div className="box textarea">
                        <span className="title">Ghi Chú:</span>
                        <textarea className="textbox" defaultValue={this.props.data ? this.props.data.ghi_chu : ""} ref="ghi_chu"></textarea>
                    </div>
                    <div className="box">
                        <span className="title">Photo:</span>
                        <select ref="photo" defaultValue={this.props.data ? this.props.data.photo : "default"} disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ?  null : 'disabled'}>
                            <option disabled selected value="default"></option>
                            {this.state.staff.map((data, i) => {
                                return (
                                    <option value={data._id} key={i}>{data.ho_ten}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="box">
                        <span className="title">Support:</span>
                        <select ref="support" defaultValue={this.props.data ? this.props.data.support : "default"} disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ?  null : 'disabled'}>
                            <option disabled selected value="default"></option>
                            {this.state.staff.map((data, i) => {
                                return (
                                    <option value={data._id} key={i}>{data.ho_ten}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="box">
                        <span className="title">Photoshop:</span>
                        <select ref="photoshop" defaultValue={this.props.data ? this.props.data.photoshop : "default"} disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ?  null : 'disabled'}>
                            <option disabled selected value="default"></option>
                            {this.state.staff.map((data, i) => {
                                return (
                                    <option value={data._id} key={i}>{data.ho_ten}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="box">
                        <span className="title">Tình Trạng:</span>
                        <select ref="tinh_trang" defaultValue={this.props.data ? this.props.data.tinh_trang : "default"}>
                            <option disabled selected value="default"></option>
                            {this.state.status.map((data, i) => {
                                return (
                                    <option value={data._id} key={i}>{data.ten_tinh_trang}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>

                <div className="column">
                    <div className="box">
                        <span className="title">Tên Khách Hàng:</span>
                        <input className="textbox" defaultValue={this.props.data ? this.props.data.ten_khach_hang : ""} ref="ten_khach_hang" type="text" disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ?  null : 'disabled'}/>
                    </div>
                    <div className="box">
                        <span className="title">Số điện thoại:</span>
                        <input className="textbox" defaultValue={this.props.data ? this.props.data.so_dien_thoai : ""} ref="so_dien_thoai" type="number" disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ?  null : 'disabled'}/>
                    </div>
                    <div className="box">
                        <span className="title">Địa Chỉ:</span>
                        <input className="textbox" defaultValue={this.props.data ? this.props.data.dia_chi : ""} ref="dia_chi" type="text" disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ?  null : 'disabled'}/>
                    </div>
                    <div className="box">
                        <span className="title">Họ Tên Bé:</span>
                        <input className="textbox" defaultValue={this.props.data ? this.props.data.ho_ten_be : ""} ref="ho_ten_be" type="text" disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ?  null : 'disabled'}/>
                    </div>
                    <div className="box">
                        <span className="title">Ngày Sinh Bé:</span>
                        <LavenDatePicker format={'DD-MM-YYYY'} date={this.state.birthday} ref="ngay_sinh_be" className="textbox" onChange={this.handleChangeBirthday} disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ?  null : 'disabled'}/>
                    </div>
                    <div className="box">
                        <span className="title">Giới Tính Bé:</span>
                        <select ref="gioi_tinh_be" defaultValue={this.props.data ? this.props.data.gioi_tinh_be : ""} disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ?  null : 'disabled'}>
                            <option disabled selected="selected">Giới tính bé: </option>
                            <option value="0">Bé trai</option>
                            <option value="1">Bé gái</option>
                        </select>
                    </div>
                    <div className="box">
                        <span className="title">Số Tuổi:</span>
                        <input className="textbox" defaultValue={this.props.data ? this.props.data.so_tuoi : ""} ref="so_tuoi" type="text" disabled/>
                    </div>
                    <div className="box">
                        <span className="title">Ngày Giao Hình:</span>
                        <LavenDatePicker format={'DD-MM-YYYY'} date={this.state.ngay_giao_hinh} ref="ngay_giao_hinh" className="textbox" onChange={this.handleChangeNgayGiaoHinh}/>
                    </div>
                </div>
            </div>
        )
    }
}

class ProductListForm extends Component {
    constructor(props) {
        super(props);
        let dateNow = new Date().getFullYear()+'-'+(new Date().getMonth() + 1) +'-'+(new Date().getDate() <= 9 ? '0' + new Date().getDate() : new Date().getDate());

        this.state = {
            dat_coc_1_time: this.props.data ? this.props.data.dat_coc_1_time : dateNow,
            dat_coc_1_des: this.props.data ? this.props.data.dat_coc_1_des : "",
            thanh_toan_l1_1_time: this.props.data ? this.props.data.thanh_toan_l1_1_time : dateNow,
            thanh_toan_l1_1_des: this.props.data ? this.props.data.thanh_toan_l1_1_des : "",
            thanh_toan_l2_1_time: this.props.data ? this.props.data.thanh_toan_l2_1_time : dateNow,
            thanh_toan_l2_1_des: this.props.data ? this.props.data.thanh_toan_l2_1_des : "",
            thanh_toan_l3_1_time: this.props.data ? this.props.data.thanh_toan_l3_1_time : dateNow,
            thanh_toan_l3_1_des: this.props.data ? this.props.data.thanh_toan_l3_1_des : ""
        }
    }

    handleSave = () => {
        if(this.refs.dat_coc_2.value !== "default" || this.refs.thanh_toan_l1_2.value !== "default" || this.refs.thanh_toan_l2_2.value !== "default" || this.refs.thanh_toan_l3_2.value !== "default") {
            let data = {
                tong: parseInt(this.refs.tong.value.replace(/,/g,"")),
                dat_coc_1: parseInt(this.refs.dat_coc_1.value.toString().replace(/\./g, '')),
                dat_coc_1_time: this.state.dat_coc_1_time,
                dat_coc_1_des: this.state.dat_coc_1_des,
                dat_coc_2: this.refs.dat_coc_2.value,
                thanh_toan_l1_1: parseInt(this.refs.thanh_toan_l1_1.value.toString().replace(/\./g, '')),
                thanh_toan_l1_1_time: this.state.thanh_toan_l1_1_time,
                thanh_toan_l1_1_des: this.state.thanh_toan_l1_1_des,
                thanh_toan_l1_2: this.refs.thanh_toan_l1_2.value,
                thanh_toan_l2_1_time: this.state.thanh_toan_l2_1_time,
                thanh_toan_l2_1_des: this.state.thanh_toan_l2_1_des,
                thanh_toan_l2_1: parseInt(this.refs.thanh_toan_l2_1.value.toString().replace(/\./g, '')),
                thanh_toan_l2_2: this.refs.thanh_toan_l2_2.value,
                thanh_toan_l3_1_time: this.state.thanh_toan_l3_1_time,
                thanh_toan_l3_1_des: this.state.thanh_toan_l3_1_des,
                thanh_toan_l3_1: parseInt(this.refs.thanh_toan_l3_1.value.toString().replace(/\./g, '')),
                thanh_toan_l3_2: this.refs.thanh_toan_l3_2.value,
                con_no_1: parseInt(this.refs.con_no_1.value.replace(/,/g,"")),
                giam_gia: 0
            }

            console.log(data);

            this.props.onSave(data);
        }else{
            alert("Chưa nhập đủ thông tin chuyển khoản!");
        }
    }

    handleChangeInput = (element, e) => {
        let data = {
            dat_coc_1: parseInt(this.refs.dat_coc_1.value.toString().replace(/\./g, '')),
            thanh_toan_l1_1: parseInt( this.refs.thanh_toan_l1_1.value.toString().replace(/\./g, '')),
            thanh_toan_l2_1: parseInt(this.refs.thanh_toan_l2_1.value.toString().replace(/\./g, '')),
            thanh_toan_l3_1: parseInt(this.refs.thanh_toan_l3_1.value.toString().replace(/\./g, '')),
            giam_gia: 0
        }

        this.refs.dat_coc_1.value=parseInt(this.refs.dat_coc_1.value.toString().replace(/\./g, ''));
        this.refs.thanh_toan_l1_1.value=parseInt(this.refs.thanh_toan_l1_1.value.toString().replace(/\./g, ''));
        this.refs.thanh_toan_l2_1.value=parseInt(this.refs.thanh_toan_l2_1.value.toString().replace(/\./g, ''));
        this.refs.thanh_toan_l3_1.value=parseInt(this.refs.thanh_toan_l3_1.value.toString().replace(/\./g, ''));

        let date_now = new Date().getFullYear()+'-'+(new Date().getMonth() + 1) +'-'+(new Date().getDate() <= 9 ? '0' + new Date().getDate() : new Date().getDate());
        let date_des = new Date();
        date_des = (date_des.getHours() <= 9 ? '0' + date_des.getHours() : date_des.getHours()) + ":" + (date_des.getMinutes() <= 9 ? '0' + date_des.getMinutes() : date_des.getMinutes()) + " " + (date_des.getDate() <= 9 ? '0' + date_des.getDate() : date_des.getDate()) + "/" + ((date_des.getMonth() + 1) <= 9 ? '0' + (date_des.getMonth() + 1) : (date_des.getMonth() + 1)) + "/" + date_des.getFullYear()

        data[element] = parseInt(e.target.value);
        if(element === 'dat_coc_1') {
            this.setState({
                dat_coc_1_time: date_now,
                dat_coc_1_des:date_des + ' - ' + localStorage.getItem("ho_ten")
            })
        }else if(element === 'thanh_toan_l1_1') {
            this.setState({
                thanh_toan_l1_1_time: date_now,
                thanh_toan_l1_1_des:date_des + ' - ' + localStorage.getItem("ho_ten")
            })
        }else if(element === 'thanh_toan_l2_1'){
            this.setState({
                thanh_toan_l2_1_time: date_now,
                thanh_toan_l2_1_des:date_des + ' - ' + localStorage.getItem("ho_ten")
            })
        }else if(element === 'thanh_toan_l3_1') {
            this.setState({
                thanh_toan_l3_1_time: date_now,
                thanh_toan_l3_1_des:date_des + ' - ' + localStorage.getItem("ho_ten")
            })
        }

        this.refs.con_no_1.value = numberWithCommas((parseInt(this.refs.tong.value.replace(/,/g,"")) - data.dat_coc_1 - data.thanh_toan_l1_1 - data.thanh_toan_l2_1 - data.thanh_toan_l3_1))
    }

    //CHỈNH SỬA GIÁ TRỊ SAU KHI XÓA SẢN PHẨM
    handleUpdateConNo = (data) => {
        data["giam_gia"] = data["giam_gia"] ? parseInt(data["giam_gia"]) : 0;
        this.refs.con_no_1.value = numberWithCommas(parseInt(this.refs.con_no_1.value.replace(/,/g,"")) + (parseInt(data.gia) * parseInt(data.so_luong) - parseInt(data.giam_gia)));
    }

    //CHỈNH SỬA GIÁ TRỊ CÒN NỢ SAU KHI THAY ĐỔI SẢN PHẨM
    handleUpdateConNoAfterChangeProduct = (old_data, new_data) => {
        old_data["giam_gia"] = old_data["giam_gia"] ? parseInt(old_data["giam_gia"]) : 0;
        this.refs.con_no_1.value = numberWithCommas(parseInt(this.refs.con_no_1.value.replace(/,/g,"")) - (Math.abs(parseInt(old_data.gia) * parseInt(old_data.so_luong)) - parseInt(old_data.giam_gia)) + (parseInt(new_data.gia) * parseInt(new_data.so_luong)) - parseInt(new_data.giam_gia));
    }

    render() { 
        return(
            <div className="product_list_form_container" style={{flex: 1}}>
                <div className="column">
                    <div className="box">
                        <span className="title">Tổng:</span>
                        <input className="textbox" ref="tong" value={numberWithCommas(this.props.product_total)} type="text" disabled/>
                    </div>
                    <div className="box">
                        <span className="title">Đặt Cọc: {this.props.data ? this.props.data.dat_coc_1_des : ""}</span>
                        <div className="douple_textbox">
                            <input className="textbox" ref="dat_coc_1" onKeyUp={(e) => this.handleChangeInput("dat_coc_1", e)} defaultValue={this.props.data ? this.props.data.dat_coc_1 : 0} type="number" disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ? (this.props.data ? (this.props.data.dat_coc_1 !== 0 ? ( localStorage.getItem('ten_chuc_vu') == 'admin' ? null : 'disabled'):null ) : null) : 'disabled'}/>
                            <select className="selectbox" ref="dat_coc_2" defaultValue={this.props.data ? this.props.data.dat_coc_2 : "2"} disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ? (this.props.data ? this.props.data.dat_coc_2 != 2 ? ( localStorage.getItem('ten_chuc_vu') == 'admin'  ? null : 'disabled'):null : null) : 'disabled'}>     
                                <option disabled selected value="2"></option>
                                <option value="0">Chuyển khoản</option>
                                <option value="1">Tiền mặt</option>
                            </select>
                        </div>
                    </div>
                    <div className="box">
                        <span className="title">Thanh Toán L1: {this.props.data ? this.props.data.thanh_toan_l1_1_des : ""}</span>
                        <div className="douple_textbox">
                            <input className="textbox" ref="thanh_toan_l1_1" onKeyUp={(e) => this.handleChangeInput("thanh_toan_l1_1", e)} defaultValue={this.props.data ? this.props.data.thanh_toan_l1_1 : 0} type="number" disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ? (this.props.data ? (this.props.data.thanh_toan_l1_1 !== 0 ? ( localStorage.getItem('ten_chuc_vu') == 'admin'  ? null : 'disabled'):null ) : null) : 'disabled'}/>
                            <select className="selectbox" ref="thanh_toan_l1_2" defaultValue={this.props.data ? this.props.data.thanh_toan_l1_2 : "2"} disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ? (this.props.data ? this.props.data.thanh_toan_l1_2 != 2 ? ( localStorage.getItem('ten_chuc_vu') == 'admin'  ? null : 'disabled'):null : null) : 'disabled'}>
                                <option disabled selected value="2"></option>
                                <option value="0">Chuyển khoản</option>
                                <option value="1">Tiền mặt</option>
                            </select>
                        </div>
                    </div>
                    <div className="box">
                        <span className="title">Thanh Toán L2: {this.props.data ? this.props.data.thanh_toan_l2_1_des : ""}</span>
                        <div className="douple_textbox">
                            <input className="textbox" ref="thanh_toan_l2_1" onKeyUp={(e) => this.handleChangeInput("thanh_toan_l2_1", e)} defaultValue={this.props.data ? this.props.data.thanh_toan_l2_1 : 0} type="number" disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ? (this.props.data ? (this.props.data.thanh_toan_l2_1 !== 0 ? ( localStorage.getItem('ten_chuc_vu') == 'admin'  ? null : 'disabled'):null ) : null) : 'disabled'}/>
                            <select className="selectbox" ref="thanh_toan_l2_2" defaultValue={this.props.data ? this.props.data.thanh_toan_l2_2 : "2"} disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ? (this.props.data ? this.props.data.thanh_toan_l2_2 != 2 ? ( localStorage.getItem('ten_chuc_vu') == 'admin'  ? null : 'disabled'):null : null) : 'disabled'}>
                                <option disabled selected value="2"></option>
                                <option value="0">Chuyển khoản</option>
                                <option value="1">Tiền mặt</option>
                            </select>
                        </div>
                    </div>
                    <div className="box">
                        <span className="title">Thanh Toán L3: {this.props.data ? this.props.data.thanh_toan_l3_1_des : ""}</span>
                        <div className="douple_textbox">
                            <input className="textbox" ref="thanh_toan_l3_1" onKeyUp={(e) => this.handleChangeInput("thanh_toan_l3_1", e)} defaultValue={this.props.data ? (this.props.data.thanh_toan_l3_1 ? this.props.data.thanh_toan_l3_1 : 0) : 0} type="number" disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ? (this.props.data ? (this.props.data.thanh_toan_l3_1 == undefined ? null : (this.props.data.thanh_toan_l3_1 !== 0 ? ( localStorage.getItem('ten_chuc_vu') == 'admin'  ? null : 'disabled'):null )) : null) : 'disabled'}/>
                            <select className="selectbox" ref="thanh_toan_l3_2" defaultValue={this.props.data ? this.props.data.thanh_toan_l3_2 : "2"} disabled={this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[2] == "1" ? (this.props.data ? this.props.data.thanh_toan_l3_2 != 2 ? ( localStorage.getItem('ten_chuc_vu') == 'admin'  ? null : 'disabled'):null : null) : 'disabled'}>
                                <option disabled selected value="2"></option>
                                <option value="0">Chuyển khoản</option>
                                <option value="1">Tiền mặt</option>
                            </select>
                        </div>
                    </div>
                    <div className="box">
                        <span className="title">Còn Nợ:</span>
                        <div className="douple_textbox">
                            <input className="textbox" ref="con_no_1" onKeyUp={(e) => this.handleChangeInput("con_no_1", e)} defaultValue={this.props.data ? numberWithCommas(this.props.data.con_no_1 ? this.props.data.con_no_1 : 0) : 0} type="text" disabled/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class StaffForm extends Component {
    constructor(props) {
        super(props);

        let ngay_sinh = this.props.data ? this.props.data.ngay_sinh ? this.props.data.ngay_sinh.split("T")[0] : null : null;
        ngay_sinh = ngay_sinh ? ngay_sinh.split("-") : null;
        ngay_sinh = ngay_sinh ? ngay_sinh[0].length == 2 ? ngay_sinh[0] + '-' + ngay_sinh[1] + '-' + ngay_sinh[2] : ngay_sinh[2] + '-' + ngay_sinh[1] + '-' + ngay_sinh[0] : null;

        this.state = {
            chuc_vu: null,
            date: ngay_sinh
        }

        getPermissions((res) => {
            this.setState({
                chuc_vu: res
            })
        });
    }

    handleChange = (date) => {
        this.setState({
          date: date
        });
    }

    handleSave = () => {
        let data = {
            ho_ten: this.refs.ho_ten.value,
            ngay_sinh: new Date(),
            so_dien_thoai: this.refs.so_dien_thoai.value,
            chuc_vu:this.refs.chuc_vu.value,
            luong_co_ban: this.refs.luong_co_ban.value,
            email: this.refs.email.value,
            password: this.refs.password.value
        }

        this.props.onSave(data);
    }

    render() {
        if(!this.state.chuc_vu) {
            return <div></div>
        }

        return(
            <div className="product_list_form_container">
                <div className="column">
                    <div className="box">
                        <span className="title">Họ Tên</span>
                        <input className="textbox" defaultValue={this.props.data ? this.props.data.ho_ten : ""} ref="ho_ten" type="text"/>
                    </div>
                    <div className="box">
                        <span className="title">Số Điện Thoại</span>
                        <input className="textbox" defaultValue={this.props.data ? this.props.data.so_dien_thoai : ""} ref="so_dien_thoai" type="number"/>
                    </div>
                    <div className="box">
                        <span className="title">Tên Người Dùng</span>
                        <input className="textbox" defaultValue={this.props.data ? this.props.data.email : ""} ref="email" type="text"/>
                    </div>
                    <div className="box">
                        <span className="title">Mật khẩu</span>
                        <input className="textbox" defaultValue={this.props.data ? this.props.data.password : ""} ref="password" type="password"/>
                    </div>
                    <div className="box">
                        <span className="title">Chức Vụ</span>
                        <select ref="chuc_vu" defaultValue={this.props.data ? this.props.data.chuc_vu : 0}>
                            {this.state.chuc_vu.map((data, i) => {
                                return (<option value={data._id.toString()}>{data.ten_nhom}</option>)
                            })}
                        </select>
                    </div>
                    <div className="box">
                        <span className="title">Lương Cơ Bản</span>
                        <input className="textbox" ref="luong_co_ban" defaultValue={this.props.data ? this.props.data.luong_co_ban ? this.props.data.luong_co_ban : 0 : 0} type="number"/>
                    </div>
                </div>
            </div>
        )
    }
}

class StaffSalaryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chuc_vu: null,
            date: this.props.data ? new Date(this.props.data.ngay_sinh) : new Date(),
            date_filter: new Date().getFullYear() + '-' + parseInt(new Date().getMonth()),
            redirect: false
        }

        getPermissions((res) => {
            this.setState({
                chuc_vu: res,
                total_photo: null,
                total_service: null,
                total: null,
                goi_chup: null,
                dich_vu: null,
                thuc_lanh: null,
                luong_thang: null
            })
        });

        this.SalaryTableCalculate(this.props.data, new Date().getFullYear(), parseInt(new Date().getMonth()));

        this.handleLoadBonus();
    }

    handleLoadBonus = async () => {
        getGoiChup((goi_chup_data) => {
            this.setState({
                goi_chup: goi_chup_data
            })
        })

        getServices((dich_vu_data) => {
            this.setState({
                dich_vu: dich_vu_data
            })
        })
    }

    handleChange = (date) => {
        this.setState({
          date: date
        });
    }

    handleSave = () => {
        let data = {
            luong_co_ban: this.refs.luong_co_ban.value,
            ngay_cong: this.refs.ngay_cong.value,
            phan_tram_thuong_dich_vu_tu_van: parseInt(this.refs.phan_tram_thuong_dich_vu_tu_van.value),
            phan_tram_thuong_goi_chup_photo: parseInt(this.refs.phan_tram_thuong_goi_chup_photo.value),
            phan_tram_thuong_goi_chup_tu_van: parseInt(this.refs.phan_tram_thuong_goi_chup_tu_van.value),  
            date_created: this.state.date_filter
        }

        updateSalaryHistory(this.state.history._id, data, (res) => {
            this.setState({
                redirect: true
            })
        })
    }

    salaryLoad = (data, month , year) => {
        //Tính bảng lương
        salaryLoad(data, month, year, (data) => {
            this.setState({
                history: data
            });

            this.refs.luong_co_ban.value = data.luong_co_ban;
            this.refs.ngay_cong.value = data.ngay_cong;
            this.refs.phan_tram_thuong_dich_vu_tu_van.value = data.phan_tram_thuong_dich_vu_tu_van;
            this.refs.phan_tram_thuong_goi_chup_photo.value = data.phan_tram_thuong_goi_chup_photo;
            this.refs.phan_tram_thuong_goi_chup_tu_van.value = data.phan_tram_thuong_goi_chup_tu_van;
            this.refs.phan_tram_thuong_goi_chup_tu_van.value = data.phan_tram_thuong_goi_chup_tu_van;
            this.refs.luong_thang.value = numberWithCommas(data.luong_thang);
            this.refs.tong_doanh_thu.value = numberWithCommas(data.total_photo);
            this.refs.thuong_dich_vu.value = numberWithCommas(data.total_service);
            this.refs.tong_thuong.value = numberWithCommas(data.total);
            this.refs.thuc_lanh.value = numberWithCommas(data.thuc_lanh);
        })
    }

    //Lọc lịch sử bảng lương
    handleFilter = (date) => {
        let dates = date.split("-");
        this.salaryLoad(this.props.data, dates[0], parseInt(dates[1]) - 1);
        this.setState({
            date_filter: dates[0] + '-' + (parseInt(dates[1]) - 1)
        })
    }

    //Tính bảng lunogw
    SalaryTableCalculate = (data, month, year) => {
        //Tính bảng lương
        salaryCalculate(data, month, year, (data) => {
            this.setState({
                history: data
            });

            this.refs.luong_co_ban.value = data.luong_co_ban;
            this.refs.ngay_cong.value = data.ngay_cong;
            this.refs.phan_tram_thuong_dich_vu_tu_van.value = data.phan_tram_thuong_dich_vu_tu_van;
            this.refs.phan_tram_thuong_goi_chup_photo.value = data.phan_tram_thuong_goi_chup_photo;
            this.refs.phan_tram_thuong_goi_chup_tu_van.value = data.phan_tram_thuong_goi_chup_tu_van;
            this.refs.phan_tram_thuong_goi_chup_tu_van.value = data.phan_tram_thuong_goi_chup_tu_van;
            this.refs.luong_thang.value = numberWithCommas(data.luong_thang);
            this.refs.tong_doanh_thu.value = numberWithCommas(data.total_photo);
            this.refs.thuong_dich_vu.value = numberWithCommas(data.total_service);
            this.refs.tong_thuong.value = numberWithCommas(data.total);
            this.refs.thuc_lanh.value = numberWithCommas(data.thuc_lanh);
        })
    }

    //Sự kiện thay đổi giá trị % thưởng
    onInputChange = (e, element) => {
        let history = this.state.history;
        history[element] = element == 'ngay_cong' ? e.target.value : parseInt(e.target.value);

        if(element === 'phan_tram_thuong_goi_chup_tu_van') {

            this.setState({
                history: history
            })
        }else if(element === 'phan_tram_thuong_goi_chup_photo') {

            this.setState({
                history: history
            })
        }else if(element === 'phan_tram_thuong_dich_vu_tu_van') {

            this.setState({
                history: history
            })
        }else if(element === 'luong_co_ban') {
            this.setState({
                history: history
            })
        }

        this.SalaryTableCalculate(this.state.history, this.state.date_filter.split("-")[0], this.state.date_filter.split("-")[1])
    }

    render() {

        if(!this.props.data) {
            return <div></div>
        }

        if(this.state.redirect) {
            return(<Redirect to="/staff_salary"/>)
        }

        const perm = this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.staff_salary;

        return(
            <div className="product_list_form_container">
                <div className="column">
                    <div className="box">
                        <span className="title">Lương Cơ Bản</span>
                        <input className="textbox" onChange={(e) => this.onInputChange(e, 'luong_co_ban')} ref="luong_co_ban" type="number" disabled={perm[2] == "1" ? '' : 'disabled'}/>
                    </div>
                    <div className="box">
                        <span className="title">Ngày Công</span>
                        <input className="textbox" onChange={(e) => this.onInputChange(e, 'ngay_cong')} ref="ngay_cong" type="text" disabled={perm[2] == "1" ? '' : 'disabled'}/>
                    </div>
                    <div className="box">
                        <span className="title">Lương Tháng</span>
                        <input className="textbox" ref="luong_thang" type="text" disabled/>
                    </div>
                    <div className="box">
                        <span className="title">% Thưởng Doanh Thu Tư Vấn (Gói chụp)</span>
                        <input className="textbox" onChange={(e) => this.onInputChange(e, 'phan_tram_thuong_goi_chup_tu_van')} ref="phan_tram_thuong_goi_chup_tu_van" type="number" disabled={perm[2] == "1" ? '' : 'disabled'}/>
                    </div>
                    <div className="box">
                        <span className="title">% Thưởng Doanh Thu Photo (Gói chụp)</span>
                        <input className="textbox" onChange={(e) => this.onInputChange(e, 'phan_tram_thuong_goi_chup_photo')} ref="phan_tram_thuong_goi_chup_photo" type="number" disabled={perm[2] == "1" ? '' : 'disabled'}/>
                    </div>
                    <div className="box">
                        <span className="title">Thưởng Doanh Thu</span>
                        <input className="textbox" ref="tong_doanh_thu" type="text" disabled/>
                    </div>
                    <div className="box">
                        <span className="title">% Thưởng Doanh Thu Tư Vấn (Dịch Vụ)</span>
                        <input className="textbox" onChange={(e) => this.onInputChange(e, 'phan_tram_thuong_dich_vu_tu_van')} ref="phan_tram_thuong_dich_vu_tu_van" type="number" disabled={perm[2] == "1" ? '' : 'disabled'}/>
                    </div>
                    <div className="box">
                        <span className="title">Thưởng Dịch Vụ</span>
                        <input className="textbox" ref="thuong_dich_vu" type="text" disabled/>
                    </div>
                    <div className="box">
                        <span className="title">Tổng Thưởng</span>
                        <input className="textbox" ref="tong_thuong" type="text" disabled/>
                    </div>
                    <div className="box">
                        <span className="title">Thực Lãnh</span>
                        <input className="textbox" ref="thuc_lanh" type="text" disabled/>
                    </div>
                </div>
                <div className="column">
                    <SalaryTableCard data={this.props.data} history={this.state.history}/>
                </div>
            </div>
        )
    }
}

class PermissionForm extends Component {
    render() {
        return(
            <div className="product_list_form_container">
                <div className="column">
                    <div className="box">
                        <span className="title">Tên Nhóm</span>
                        <input className="textbox" ref="branch" type="text"/>
                    </div>
                </div>
            </div>
        )
    }
}

export {
    BillForm,
    ProductListForm,
    StaffForm,
    PermissionForm,
    StaffSalaryForm
}