import React, {Component} from 'react';

import {HeaderBar} from '../components/Header_bar';
import {ProductCard} from '../components/Cards';

class Products extends Component {
    render() {
        return(
            <div>
                <HeaderBar title="Sản Phẩm"/>
                <div className="product_container">
                    <ProductCard type="0"/>
                    <ProductCard type="1" image="https://cf.shopee.vn/file/462c8898226fbe3a782b8afdbb42dda2" title="Baby Tiết Kiệm" sub_title="1.000.000 đ"/>
                    <ProductCard type="1" image="https://images-na.ssl-images-amazon.com/images/I/81tEfci6Q%2BL.jpg" title="Baby Cơ Bản" sub_title="1.000.000 đ"/>
                    <ProductCard type="1" image="https://anhcuoihanoi.net/wp-content/uploads/2019/04/ket-qua-hinh-anh-cho-chup-hinh-cho-be-tron-6-thang.jpeg" title="Kèm Ba Mẹ" sub_title="1.000.000 đ"/>
                    <ProductCard type="1" image="https://toplist.vn/images/800px/bo-baby-photography-chup-anh-em-be-323271.jpg" title="Chỉnh Sửa Baby" sub_title="1.000.000 đ"/>
                    <ProductCard type="1" image="https://cf.shopee.vn/file/462c8898226fbe3a782b8afdbb42dda2" title="Baby Cao Cấp" sub_title="1.000.000 đ"/>
                    <ProductCard type="1" image="https://images-na.ssl-images-amazon.com/images/I/81tEfci6Q%2BL.jpg" title="New Born Cơ Bản" sub_title="1.000.000 đ"/>
                    <ProductCard type="1" image="https://toplist.vn/images/800px/bo-baby-photography-chup-anh-em-be-323271.jpg" title="New Born Cao Cấp" sub_title="1.000.000 đ"/>
                    <ProductCard type="1" image="https://cf.shopee.vn/file/462c8898226fbe3a782b8afdbb42dda2" title="Bầu" sub_title="1.000.000 đ"/>
                    <ProductCard type="1" image="https://images-na.ssl-images-amazon.com/images/I/81tEfci6Q%2BL.jpg" title="Gia Đình" sub_title="1.000.000 đ"/>
                    <ProductCard type="1" image="https://cf.shopee.vn/file/462c8898226fbe3a782b8afdbb42dda2" title="Baby Ngoại Cảnh" sub_title="1.000.000 đ"/>
                    <ProductCard type="1" image="https://anhcuoihanoi.net/wp-content/uploads/2019/04/ket-qua-hinh-anh-cho-chup-hinh-cho-be-tron-6-thang.jpeg" title="Chỉnh Sửa Gia Đình" sub_title="1.000.000 đ"/>
                    <ProductCard type="1" image="https://toplist.vn/images/800px/bo-baby-photography-chup-anh-em-be-323271.jpg" title="Chỉnh Sửa New Born" sub_title="1.000.000 đ"/>
                </div>
            </div>
        )
    }
}

export default Products;