import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import {getBill, getBranch, getGoiChup, getServices, getStaff, getStatus, APIs, getPermissions} from '../functions/Api';
import {HashSpiner} from './Spiners';
import {numberWithCommas, removeVietnameseTones, findWithAttr, salaryLoad, salaryCalculate} from '../functions/Global';

import '../styles/components/Table.scss';

class BillTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branch: null,
            status: null,
            status_color: null
        }

        getBranch((res) => {
          this.setState({
            branch: res
          })
        })

        getStatus((res) => {
          this.setState({
            status: res
          })
        });
    }

    render() {
        if(this.props.data === null || this.state.branch === null || this.state.status === null) {
          return(<div className="load_view"><HashSpiner/></div>)
        }
        return(
            <div className="table_view">
              <div className="table">
                <StickyTable>
                  <Row className="header">
                    <Cell>STT</Cell>
                    <Cell>Chi Nhánh</Cell>
                    <Cell>Giờ Chụp</Cell>
                    <Cell>Ngày Chụp</Cell>
                    <Cell>Tên Khách Hàng</Cell>
                    <Cell>SĐT</Cell>
                    <Cell>Họ Tên Bé</Cell>
                    <Cell>Tổng</Cell>
                    <Cell>Đã Thu</Cell>
                    <Cell>Còn Nợ</Cell>
                    <Cell>Tình Trạng</Cell>
                    <Cell>Ngày Giao</Cell>
                    <Cell>Ghi Chú</Cell>
                  </Row>
                  {this.props.data.map((data, i) => {
                      let date = new Date(data.ngay_chup);
                      let ngay_chup = data.ngay_chup
                      ngay_chup = ngay_chup ? ngay_chup.split("-")[0].length == 2 ? ngay_chup : ngay_chup.split("-")[2] + '-' + ngay_chup.split("-")[1] + '-' + ngay_chup.split("-")[0] : null

                      let ngay_giao_hinh = data.ngay_giao_hinh;

                      let time_take_pictures = (date.getHours() <= 9 ? "0" + date.getHours() : date.getHours()) + ":" + (date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes()) + ":" + (date.getSeconds() <= 9 ? "0" + date.getSeconds() : date.getSeconds())
                      let birth_day = new Date(data.ngay_sinh_be);
                      let birth_day_final =  birth_day.getFullYear() + "-" + (birth_day.getMonth() <= 9 ? "0" + birth_day.getMonth() : birth_day.getMonth()) + "-" + (birth_day.getDate() <= 9 ? "0" + birth_day.getDate() : birth_day.getDate()) 
                      let packages = ["Baby ngoại cảnh", "Baby cao cấp"]
                      let services = ["ALBUM 13 x 18"]

                      let status = this.state.status;

                      return(
                        <Row className="row">
                          <Cell><Link to={this.props.isDelete ? `/bill_restore/${data._id}` : `/bill_edit/${data._id}`}>{i + 1}</Link></Cell>
                          <Cell><Link to={this.props.isDelete ? `/bill_restore/${data._id}` : `/bill_edit/${data._id}`}>{findWithAttr(this.state.branch,'_id',parseInt(data.chi_nhanh)) > -1 ? this.state.branch[findWithAttr(this.state.branch,'_id',parseInt(data.chi_nhanh))].ten_chi_nhanh : ''}</Link></Cell>
                          <Cell><Link to={this.props.isDelete ? `/bill_restore/${data._id}` : `/bill_edit/${data._id}`}>{data.gio_chup}</Link></Cell>
                          <Cell><Link to={this.props.isDelete ? `/bill_restore/${data._id}` : `/bill_edit/${data._id}`}>{ngay_chup}</Link></Cell>
                          <Cell><Link to={this.props.isDelete ? `/bill_restore/${data._id}` : `/bill_edit/${data._id}`}>{data.ten_khach_hang}</Link></Cell>
                          <Cell><Link to={this.props.isDelete ? `/bill_restore/${data._id}` : `/bill_edit/${data._id}`}>{data.so_dien_thoai}</Link></Cell>
                          <Cell><Link to={this.props.isDelete ? `/bill_restore/${data._id}` : `/bill_edit/${data._id}`}>{data.ho_ten_be}</Link></Cell>
                          <Cell><Link to={this.props.isDelete ? `/bill_restore/${data._id}` : `/bill_edit/${data._id}`}>{numberWithCommas(data.tong)} đ</Link></Cell>
                          <Cell><Link to={this.props.isDelete ? `/bill_restore/${data._id}` : `/bill_edit/${data._id}`}>{numberWithCommas(data.dat_coc_1 + data.thanh_toan_l1_1 + data.thanh_toan_l2_1 + (data.thanh_toan_l3_1 ? data.thanh_toan_l3_1 : 0))} đ</Link></Cell>
                          <Cell><Link to={this.props.isDelete ? `/bill_restore/${data._id}` : `/bill_edit/${data._id}`}>{numberWithCommas(data.tong - (data.dat_coc_1 + data.thanh_toan_l1_1 + data.thanh_toan_l2_1 + (data.thanh_toan_l3_1 ? data.thanh_toan_l3_1 : 0) + data.giam_gia))} đ</Link></Cell>
                          <Cell><div className={`row ${status[findWithAttr(status, '_id', data.tinh_trang)] ? status[findWithAttr(status, '_id', data.tinh_trang)].color : null}`}><Link to={this.props.isDelete ? `/bill_restore/${data._id}` : `/bill_edit/${data._id}`}>{status[findWithAttr(status, '_id', data.tinh_trang)] ? status[findWithAttr(status, '_id', data.tinh_trang)].ten_tinh_trang : null}</Link></div></Cell>
                          <Cell><Link to={this.props.isDelete ? `/bill_restore/${data._id}` : `/bill_edit/${data._id}`}>{ngay_giao_hinh}</Link></Cell>
                          <Cell><Link to={this.props.isDelete ? `/bill_restore/${data._id}` : `/bill_edit/${data._id}`}>{data.ghi_chu}</Link></Cell>
                        </Row>
                      )
                  }, this)}
                </StickyTable>
              </div>
            </div>
        )
    }
}

class CustomersTableContainer extends Component {

    render() {
        if(!this.props.bill || !this.props.branch) {
          return <div></div>
        }
        return(
            <div className="table_view">
              <div className="table">
                <StickyTable>
                  <Row className="header">
                    <Cell style={{width: "32px"}}>STT</Cell>
                    <Cell>Chi Nhánh</Cell>
                    <Cell>Tên Khách Hàng</Cell>
                    <Cell>SĐT</Cell>
                    <Cell>Địa Chỉ</Cell>
                  </Row>
                  {this.props.bill.map((data, i) => {
                      return(
                        <Row className="row">
                          <Cell><a>{i + 1}</a></Cell>
                          <Cell><a>{findWithAttr(this.props.branch,'_id',parseInt(data.chi_nhanh)) > -1 ? this.props.branch[findWithAttr(this.props.branch,'_id',parseInt(data.chi_nhanh))].ten_chi_nhanh : ''}</a></Cell>
                          <Cell><a>{data.ten_khach_hang}</a></Cell>
                          <Cell><a>{data.so_dien_thoai}</a></Cell>
                          <Cell><a>{data.dia_chi}</a></Cell>
                          <Cell><a></a></Cell>
                        </Row>
                      )
                  }, this)}
                </StickyTable>
              </div>
            </div>
        )
    }
}

class BillEditProductTable extends Component {
    render() {
        return(
            <div className="table_view bill_edit" style={{flex: 1}}>
              <div className="table">
                <StickyTable>
                  <Row className="header">
                    <Cell style={{width: "32px"}}>STT</Cell>
                    <Cell>Sản Phẩm</Cell>
                    <Cell>Đơn Giá</Cell>
                    <Cell>Số Lượng</Cell>
                    <Cell>Giảm Giá</Cell>
                    <Cell>Tổng</Cell>
                  </Row>
                    {this.props.data.map((data, i) => {
                      return(
                        <Row className="row" onClick={() => this.props.onEdit(i)}>
                          <Cell>{i + 1}</Cell>
                          <Cell>{data.ten_goi}</Cell>
                          <Cell>{numberWithCommas(data.gia)} đ</Cell>
                          <Cell>{data.so_luong}</Cell>
                          <Cell>{data.giam_gia ? numberWithCommas(data.giam_gia) : 0} đ</Cell>
                          <Cell>{numberWithCommas((parseInt(data.gia) * parseInt(data.so_luong) * ((100 - parseInt(data.chiet_khau))/100)) - (data.giam_gia ? parseInt(data.giam_gia) : 0))} đ</Cell>
                        </Row>
                      )
                    })}
                </StickyTable>
              </div>
            </div>
        )
    }
}

class TakePicturePackageTable extends Component {
    handleEdit = (id) => {
      this.props.handleOpenModal(id);
    }

    render() {
        return(
            <div className="table_view">
              <div className="table">
                <StickyTable>
                  <Row className="header">
                    <Cell style={{width: "32px"}}>STT</Cell>
                    <Cell>Tên Gói</Cell>
                    <Cell>Giá Tiền</Cell>
                  </Row>
                  {this.props.data.map((data, i) => {
                    return(
                      <Row className="row" onClick={() => this.handleEdit(data._id)}>
                        <Cell>{i + 1}</Cell>
                        <Cell>{data.ten_goi}</Cell>
                        <Cell>{data.gia}</Cell>
                      </Row>
                    )
                  })}
                </StickyTable>
              </div>
            </div>
        )
    }
}

class SpendingTable extends Component {
    handleEdit = (id) => {
      this.props.handleOpenModal(id);
    }

    render() {
        return(
            <div className="table_view">
              <div className="table">
                <StickyTable>
                  <Row className="header">
                    <Cell style={{width: "32px"}}>STT</Cell>
                    <Cell>Ngày Chi</Cell>
                    <Cell>Nội Dung</Cell>
                    <Cell>Số Tiền</Cell>
                    <Cell>Trạng Thái Thanh Toán</Cell>
                    <Cell>Người Chi</Cell>
                  </Row>
                  {this.props.data.map((data, i) => {
                    let date = data.date.split("T")[0];
                    date = date.split("-");
                    date = date[2] + "-" + date[1] + "-" + date[0];

                    return(
                      <Row className="row" onClick={() => this.handleEdit(data._id)}>
                        <Cell>{i + 1}</Cell>
                        <Cell>{date}</Cell>
                        <Cell>{data.noi_dung}</Cell>
                        <Cell>{data.so_tien ? numberWithCommas(data.so_tien) : 0} đ</Cell>
                        <Cell>{data.type == 0 ? "Chuyển khoản" : "Tiền mặt"}</Cell>
                        <Cell>{data.user}</Cell>
                      </Row>
                    )
                  })}
                </StickyTable>
              </div>
            </div>
        )
    }
}

class SpendingTableAnalytics extends Component {
  render() {
      if(!this.props.data) {
        return <div></div>
      }

      return(
          <div className="table_view">
            <div className="table" style={{margin: "0"}}>
              <StickyTable>
                <Row className="header">
                  <Cell style={{width: "32px"}}>STT</Cell>
                  <Cell>Ngày Chi</Cell>
                  <Cell>Nội Dung</Cell>
                  <Cell>Số Tiền</Cell>
                  <Cell>Trạng Thái Thanh Toán</Cell>
                  <Cell>Người Chi</Cell>
                </Row>
                {this.props.data.map((data, i) => {
                  let ngay_chi = data.date.split("T")[0].split("-");
                  ngay_chi = ngay_chi[0].length == 2 ? data.date.split("T")[0] : ngay_chi[2] + '-' + ngay_chi[1] + '-' + ngay_chi[0];

                  return(
                    <Row className="row">
                      <Cell>{i + 1}</Cell>
                      <Cell>{ngay_chi}</Cell>
                      <Cell>{data.noi_dung}</Cell>
                      <Cell>{data.so_tien ? numberWithCommas(data.so_tien) : 0} đ</Cell>
                      <Cell>{data.type == 0 ? "Chuyển khoản" : "Tiền mặt"}</Cell>
                      <Cell>{data.user}</Cell>
                    </Row>
                  )
                })}
              </StickyTable>
            </div>
          </div>
      )
  }
}

class ServicesTable extends Component {
    handleEdit = (id) => {
      this.props.handleOpenModal(id);
    }

    render() {
        return(
            <div className="table_view">
              <div className="table">
                <StickyTable>
                  <Row className="header">  
                    <Cell style={{width: "32px"}}>STT</Cell>
                    <Cell>Tên Dịch Vụ</Cell>
                    <Cell>Giá Tiền</Cell>
                  </Row>
                    {this.props.data.map((data, i) => {
                      return(
                        <Row className="row" onClick={() => this.handleEdit(data._id)}>
                          <Cell>{i + 1}</Cell>
                          <Cell><a>{data.ten_goi}</a></Cell>
                          <Cell><a>{data.gia}</a></Cell>
                        </Row>
                      )
                    })}
                </StickyTable>
              </div>
            </div>
        )
    }
}

class StaffTable extends Component {

  render() {
      return(
          <div className="table_view">
            <div className="table">
              <StickyTable>
                <Row className="header">
                  <Cell style={{width: "32px"}}>STT</Cell>
                  <Cell>Họ Tên</Cell>
                  <Cell>SĐT</Cell>
                  <Cell>CHỨC VỤ</Cell>
                  <Cell>LƯƠNG CB</Cell>
                </Row>
                  {this.props.data.map((data, i) => {
                    let birthday = new Date(data.ngay_sinh);
                    return(
                      <Row className="row">
                        <Cell><Link to={`/staff_edit/${data._id}`}>{i + 1}</Link></Cell>
                        <Cell><Link to={`/staff_edit/${data._id}`}>{data.ho_ten}</Link></Cell>
                        <Cell><Link to={`/staff_edit/${data._id}`}>{data.so_dien_thoai}</Link></Cell>
                        <Cell><Link to={`/staff_edit/${data._id}`}>{this.props.perms[findWithAttr(this.props.perms, '_id', data.chuc_vu)] ? this.props.perms[findWithAttr(this.props.perms, '_id', data.chuc_vu)].ten_nhom : null}</Link></Cell>
                        <Cell><Link to={`/staff_edit/${data._id}`}>{data.luong_co_ban}</Link></Cell>
                      </Row>
                    )
                  })}
              </StickyTable>
            </div>
          </div>
      )
  }
}

class SalaryTable extends Component {
  render() {
    if(this.props.data.length <= 0) {
      return(<div className="load_view"><HashSpiner/></div>)
    }

    const perm = this.props.perms[findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.staff_salary;

      return(
          <div className="table_view">
            <div className="table">
              <StickyTable>
                <Row className="header">
                  <Cell style={{width: "32px"}}>STT</Cell>
                  <Cell>Họ Tên</Cell>
                  <Cell>Lương Cơ Bản</Cell>
                  <Cell>Ngày Công</Cell>
                  <Cell>Lương Tháng</Cell>
                  <Cell>% Thưởng Doanh Thu Tư Vấn (GC)</Cell>
                  <Cell>% Thưởng Doanh Thu Photo</Cell>
                  <Cell>% Thưởng Photoshop (PTS)</Cell>
                  <Cell>Tổng Thưởng Doanh Thu</Cell>
                  <Cell>% Thưởng Doanh Thu Tư Vấn (DV)</Cell>
                  <Cell>Thưởng Dịch Vụ</Cell>
                  <Cell>Tổng Thưởng</Cell>
                  <Cell>Thực Lãnh</Cell>
                </Row>
                  {this.props.data.map((data, i) => {
                    return(
                      <Row className="row">
                        <Cell>{i + 1}</Cell>
                        <Cell>{data.ho_ten}</Cell>
                        <Cell style={{width: "150px"}}><input style={{width: "150px"}} className="textbox" defaultValue={data.luong_co_ban} onChange={(e) => this.props.onInputChange(e, 'luong_co_ban', data._id)} ref="luong_co_ban" type="number" disabled={perm[2] == "1" ? '' : 'disabled'}/></Cell>
                        <Cell style={{width: "150px"}}><input style={{width: "150px"}} className="textbox" defaultValue={data.ngay_cong} onChange={(e) => this.props.onInputChange(e, 'ngay_cong', data._id)} ref="ngay_cong" type="text" disabled={perm[2] == "1" ? '' : 'disabled'}/></Cell>
                        <Cell style={{width: "150px"}}><input style={{width: "150px"}} className="textbox" value={numberWithCommas(data.luong_thang)} ref="luong_thang" type="text" disabled/></Cell>
                        <Cell style={{width: "150px"}}><input style={{width: "150px"}} className="textbox" defaultValue={data.phan_tram_thuong_goi_chup_tu_van} onChange={(e) => this.props.onInputChange(e, 'phan_tram_thuong_goi_chup_tu_van', data._id)} ref="phan_tram_thuong_goi_chup_tu_van" type="number" disabled={perm[2] == "1" ? '' : 'disabled'}/></Cell>
                        <Cell style={{width: "150px"}}><input style={{width: "150px"}} className="textbox" defaultValue={data.phan_tram_thuong_goi_chup_photo} onChange={(e) => this.props.onInputChange(e, 'phan_tram_thuong_goi_chup_photo', data._id)} ref="phan_tram_thuong_goi_chup_photo" type="number" disabled={perm[2] == "1" ? '' : 'disabled'}/></Cell>
                        <Cell style={{width: "150px"}}><input style={{width: "150px"}} className="textbox" defaultValue={data.phan_tram_thuong_photoshop} onChange={(e) => this.props.onInputChange(e, 'phan_tram_thuong_photoshop', data._id)} ref="phan_tram_thuong_photoshop" type="number" disabled={perm[2] == "1" ? '' : 'disabled'}/></Cell>
                        <Cell style={{width: "150px"}}><input style={{width: "150px"}} className="textbox" value={numberWithCommas(data.total_photo)} ref="tong_doanh_thu" type="text" disabled/></Cell>
                        <Cell style={{width: "150px"}}><input style={{width: "150px"}} className="textbox" defaultValue={data.phan_tram_thuong_dich_vu_tu_van} onChange={(e) => this.props.onInputChange(e, 'phan_tram_thuong_dich_vu_tu_van', data._id)} ref="phan_tram_thuong_dich_vu_tu_van" type="number" disabled={perm[2] == "1" ? '' : 'disabled'}/></Cell>
                        <Cell style={{width: "150px"}}><input style={{width: "150px"}} className="textbox" value={numberWithCommas(data.total_service)} ref="thuong_dich_vu" type="text" disabled/></Cell>
                        <Cell style={{width: "150px"}}><input style={{width: "150px"}} className="textbox" value={numberWithCommas(data.total)} ref="tong_thuong" type="text" disabled/></Cell>
                        <Cell style={{width: "150px"}}><input style={{width: "150px"}} className="textbox" value={numberWithCommas(data.thuc_lanh)} ref="thuc_lanh" type="text" disabled/></Cell>
                      </Row>
                    )
                  })}
              </StickyTable>
            </div>
          </div>
      )
  }
}

class ChamCongTable extends Component {
  render() {
      return(
          <div className="table_view">
            <div className="table">
              <StickyTable>
                <Row className="header">
                  <Cell style={{width: "32px"}}>STT</Cell>
                  <Cell>Họ Tên</Cell>
                </Row>
                  {this.props.data.map((data, i) => {
                    let birthday = new Date(data.ngay_sinh);
                    return(
                      <Row className="row">
                        <Cell><Link to={`/cham_cong_edit/${data._id}`}>{i + 1}</Link></Cell>
                        <Cell><Link to={`/cham_cong_edit/${data._id}`}>{data.ho_ten}</Link></Cell>
                      </Row>
                    )
                  })}
              </StickyTable>
            </div>
          </div>
      )
  }
}

class PermissionTable extends Component {
  render() {
    return(
      <div className="table_view">
        <div className="table">
          <StickyTable>
            <Row className="header">
              <Cell style={{width: "32px"}}>STT</Cell>
              <Cell>Tên Nhóm</Cell>
            </Row>
              <Row className="row">
                <Cell><a>1</a></Cell>
                <Cell><a>Kỹ Thuật</a></Cell>
              </Row>
              <Row className="row">
                <Cell><a>2</a></Cell>
                <Cell><a>Tư Vấn</a></Cell>
              </Row>
              <Row className="row">
                <Cell><a>3</a></Cell>
                <Cell><a>Quản Lý</a></Cell>
              </Row>
              <Row className="row">
                <Cell><a>4</a></Cell>
                <Cell><a>Admin</a></Cell>
              </Row>
          </StickyTable>
        </div>
      </div>
    )
  }
}

class BranchTable extends Component {
  render() {
    return(
      <div className="table_view">
        <div className="table">
          <StickyTable>
            <Row className="header">
              <Cell style={{width: "32px"}}>STT</Cell>
              <Cell>Tên Chi Nhánh</Cell>
              <Cell>Địa Chỉ</Cell>
            </Row>
              {this.props.data.map((data, i) => {
                return(
                  <Row className="row" key={i} onClick={() => this.props.onClick(data._id,i)}>
                    <Cell>{i + 1}</Cell>
                    <Cell><div className={`row ${data.color}`}>{data.ten_chi_nhanh}</div></Cell>
                    <Cell>{data.dia_chi}</Cell>
                  </Row>
                )
              })}
          </StickyTable>
        </div>
      </div>
    )
  }
}

class StatusTable extends Component {
  render() {
    return(
      <div className="table_view">
        <div className="table">
          <StickyTable>
            <Row className="header">
              <Cell style={{width: "32px"}}>STT</Cell>
              <Cell>Tên Tình Trạng</Cell>
            </Row>
              {this.props.data.map((data, i) => {
                return(
                  <Row className="row" key={i} onClick={() => this.props.onClick(data._id,i)}>
                    <Cell>{i + 1}</Cell>
                    <Cell><div className={`row ${data.color}`}>{data.ten_tinh_trang}</div></Cell>
                  </Row>
                )
              })}
          </StickyTable>
        </div>
      </div>
    )
  }
}

class AnalyticsTable extends Component {
  constructor(props) {
      super(props);
      this.state = {
          bill: null,
          branch: null,
          status: null,
          status_color: null
      }

      getBill((res) => {
        let data = res.sort((a, b) => {return new Date(b.ngay_chup) - new Date(a.ngay_chup)})
        this.setState ({
          bill: data
        })
      });

      getBranch((res) => {
        this.setState({
          branch: res
        })
      })

      getStatus((res) => {
        let status = [];
        let colors = [];

        res.map((data) => {
          status.push(data.ten_tinh_trang);
          colors.push(data.color);
        });

        this.setState({
          status: status,
          status_color: colors
        })
      });
  }

  render() {
    if(!this.props.data || !this.state.status || !this.state.status_color) {
      return(
        <div></div>
      )
    }

    return(
      <div className="table_view">
        <div className="table" style={{margin: "20px"}}>
          <StickyTable>
            <Row className="header">
              <Cell>STT</Cell>
              <Cell>Chi Nhánh</Cell>
              <Cell>Giờ Chụp</Cell>
              <Cell>Ngày Chụp</Cell>
              <Cell>Tên Khách Hàng</Cell>
              <Cell>SĐT</Cell>
              <Cell>Họ Tên Bé</Cell>
              <Cell>Tổng</Cell>
              <Cell>Đã Thu</Cell>
              <Cell>Thu Trong Ngày</Cell>
              <Cell>Còn Nợ</Cell>
              <Cell>Tình Trạng</Cell>
              <Cell>Ghi Chú</Cell>
            </Row>
            {this.props.data.map((data, i) => {
                let date = new Date(data.ngay_chup);
                let ngay_chup = data.ngay_chup.split("-");
                ngay_chup = ngay_chup[0].length !== 2 ? ngay_chup[2] + '-' + ngay_chup[1] + '-' + ngay_chup[0] : ngay_chup[0] + '-' + ngay_chup[1] + '-' + ngay_chup[2]

                let time_take_pictures = (date.getHours() <= 9 ? "0" + date.getHours() : date.getHours()) + ":" + (date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes()) + ":" + (date.getSeconds() <= 9 ? "0" + date.getSeconds() : date.getSeconds())
                let birth_day = new Date(data.ngay_sinh_be);
                let birth_day_final =  birth_day.getFullYear() + "-" + (birth_day.getMonth() <= 9 ? "0" + birth_day.getMonth() : birth_day.getMonth()) + "-" + (birth_day.getDate() <= 9 ? "0" + birth_day.getDate() : birth_day.getDate()) 
                let packages = ["Baby ngoại cảnh", "Baby cao cấp"]
                let services = ["ALBUM 13 x 18"]

                return(
                  <Row className="row">
                    <Cell><Link to={`/bill_edit/${data._id}`}>{i + 1}</Link></Cell>
                    <Cell><Link to={`/bill_edit/${data._id}`}>{this.state.branch ? findWithAttr(this.state.branch,'_id',parseInt(data.chi_nhanh)) > -1 ? this.state.branch[findWithAttr(this.state.branch,'_id',parseInt(data.chi_nhanh))].ten_chi_nhanh : '' : null}</Link></Cell>
                    <Cell><Link to={`/bill_edit/${data._id}`}>{data.gio_chup}</Link></Cell>
                    <Cell><Link to={`/bill_edit/${data._id}`}>{ngay_chup}</Link></Cell>
                    <Cell><Link to={`/bill_edit/${data._id}`}>{data.ten_khach_hang}</Link></Cell>
                    <Cell><Link to={`/bill_edit/${data._id}`}>{data.so_dien_thoai}</Link></Cell>
                    <Cell><Link to={`/bill_edit/${data._id}`}>{data.ho_ten_be}</Link></Cell>
                    <Cell><Link to={`/bill_edit/${data._id}`}>{numberWithCommas(data.tong)} đ</Link></Cell>
                    <Cell><Link to={`/bill_edit/${data._id}`}>{numberWithCommas(data.dat_coc_1 + data.thanh_toan_l1_1 + data.thanh_toan_l2_1 + (data.thanh_toan_l3_1 ? data.thanh_toan_l3_1 : 0))} đ</Link></Cell>
                    <Cell><Link to={`/bill_edit/${data._id}`}>{numberWithCommas(data.thu_trong_ngay)} đ</Link></Cell>
                    <Cell><Link to={`/bill_edit/${data._id}`}>{numberWithCommas(data.tong - (data.dat_coc_1 + data.thanh_toan_l1_1 + data.thanh_toan_l2_1 + (data.thanh_toan_l3_1 ? data.thanh_toan_l3_1 : 0) + data.giam_gia))} đ</Link></Cell>
                    <Cell><div className={`row ${this.state.status_color[data.tinh_trang]}`}><Link to={this.props.isDelete ? `/bill_restore/${data._id}` : `/bill_edit/${data._id}`}>{this.state.status[data.tinh_trang]}</Link></div></Cell>
                    <Cell><Link to={`/bill_edit/${data._id}`}>{data.ghi_chu}</Link></Cell>
                  </Row>
                )
            }, this)}
          </StickyTable>
        </div>
      </div>
    )
  }
}

export {
    CustomersTableContainer,
    BillTableContainer,
    BillEditProductTable,
    TakePicturePackageTable,
    ServicesTable,
    StaffTable,
    PermissionTable,
    BranchTable,
    AnalyticsTable,
    StatusTable,
    SalaryTable,
    SpendingTable,
    SpendingTableAnalytics,
    ChamCongTable
}