import React, {Component} from 'react';
import {APIs} from '../functions/Api';
import { findWithAttr} from '../functions/Global';

class TabBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab_active: this.props.tab
        }
    }

    handleChangeTab = (tab) => {
        this.setState({
            tab_active: tab
        });

        this.props.onChangeTab(tab);
    }

    render() {

        return(
            <div className="tab_bar">
                {this.props.data.map((data , i) => {
                    //TAB LỚN HƠN THÁNG HIỆN TẠI => DISABLED
                    if(data > (new Date().getMonth() + 1)) {
                        return(
                            <div key={i} className="tab disabled">
                                <span className="tab_title">{data}</span>
                            </div>
                        )
                    }else{
                        return(
                            <div key={i} onClick={() => this.handleChangeTab(i)} className={this.state.tab_active === i ? "tab active" : "tab"}>
                                <span className="tab_title">{data}</span>
                            </div>
                        )
                    }
                })}
            </div>
        )
    }
}

class ChamCongTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
            perms: null
        }

        APIs.post('/get_permissions', '', null, (res) => {
            this.setState({
                perms: res.data
            })
        })
    }

    handleChamCong = (e, type, date) => {
        let sang = this.props.sang;
        let chieu = this.props.chieu;
        var counts_sang = {};
        var counts_chieu = {};

        e.target.checked ? (type == 'sang' ? sang[date - 1] = 1 : chieu[date - 1] = 1) : (type == 'sang' ? sang[date - 1] = 0 : chieu[date - 1] = 0)

        for (var i = 0; i < sang.length; i++) {
          var num = sang[i];
          counts_sang[num] = counts_sang[num] ? counts_sang[num] + 1 : 1;
        }

        for (var i = 0; i < chieu.length; i++) {
          var num = chieu[i];
          counts_chieu[num] = counts_chieu[num] ? counts_chieu[num] + 1 : 1;
        }

        let ngay_cong_sang = counts_sang[1] ? counts_sang[1] / 2 : 0;
        let ngay_cong_chieu = counts_chieu[1] ? counts_chieu[1] / 2 : 0

        let data = {
            month: this.props.months,
            year: this.props.year,
            sang: sang,
            chieu: chieu,
            tong_ngay_cong: (ngay_cong_sang + ngay_cong_chieu),
            user_id: this.props.user_id
        }

        APIs.post('/cham_cong_table','', {data: data}, (res) => {
            console.log(res);
        })
    }

    render() {
        if(!this.props.date || !this.state.perms) {
            return <div></div>
        }

        const perms = this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons;

        return(
            <div className="tab_content_container cham_cong_table">
                <div className="tab_content_header_container cham_cong_table">
                    <h1 className="tab_content_header">Chấm công tháng {this.props.months} năm {this.props.year}</h1>
                    <div className="tab_header_right_item_container cham_cong_table">
                        {this.props.date.map((data, i) => {
                            return <span className="tab_header_item_title">{data}</span>
                        })}
                    </div>
                </div>
                <div className="tab_content cham_cong_table">
                    <div className="tab_side_bar">
                        <span className="tab_side_bar_title">Sáng</span>
                        <span className="tab_side_bar_title">Chiều</span>
                    </div>
                    <div className="tab_tick_container">
                        <div className="tab_tick">
                            {this.props.date.map((result, i) => {
                                const today = new Date().getDate().toString();
                                const month = this.props.months.toString();
                                const monthNow = (new Date().getMonth() + 1).toString();

                                return(
                            <div className="column" key={"column" + i}>
                                <div className="checkbox" key={"bill" + i}>
                                    <input type="checkbox" onChange={(e) => this.handleChamCong(e,'sang',result)} defaultChecked={this.props.sang[result - 1] == 1 ? "checked" : null} disabled={(result).toString() == today && month == monthNow ? (this.props.sang[result - 1] == 1 ? (perms.cham_cong[2] == "1" ? null : 'disabled') : null) : perms.cham_cong[2] == "1" ? null : 'disabled'}/>
                                </div>
                                <div className="checkbox" key={"bills" + i}>
                                    <input type="checkbox" onChange={(e) => this.handleChamCong(e,'chieu',result)} defaultChecked={this.props.chieu[result - 1] == 1 ? "checked" : null} disabled={(result).toString() == today && month == monthNow ? (this.props.chieu[result - 1] == 1 ? (perms.cham_cong[2] == "1" ? null : 'disabled') : null) : perms.cham_cong[2] == "1" ? null : 'disabled'}/>
                                </div>
                            </div>
                       
                                )
                            }, this)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {
    ChamCongTable,
    TabBar}
;