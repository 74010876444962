import {
    getBill,
    getGoiChup,
    getServices,
    getSalaryHistory,
    APIs
} from './Api';

const DEBUG = false;
const TYPE = "bienhoa"; // vang || hethong || demo || bienhoa
const LINK = DEBUG ? `http://localhost:${TYPE == 'hethong' ? 8005 :  TYPE == 'vang' ? 8004 : TYPE == 'bienhoa' ? 8007 : 8006}` : `http://server.mohome.vn:${TYPE == 'hethong' ? 8005 :  TYPE == 'vang' ? 8004 : TYPE == 'bienhoa' ? 8007 : 8006}`;
const API = DEBUG ? `http://localhost:${TYPE == 'hethong' ? 8005 :  TYPE == 'vang' ? 8004 : TYPE == 'bienhoa' ? 8007 : 8006}/api/v1` : `http://server.mohome.vn:${TYPE == 'hethong' ? 8005 :  TYPE == 'vang' ? 8004 : TYPE == 'bienhoa' ? 8007 : 8006}/api/v1`;

export const APP_VERSION = "3.20.50"
export const APP_VERSION_LABEL = `Beta [ ${APP_VERSION} ]`

function numberWithCommas(x) {
    if(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return 0;
}

function findWithAttr(array, attr, value) {
    if(array) {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
    }
    return -1;
}

//ĐỔI ĐỊNH DẠNG NGÀY MỚI SANG CŨ
export const transDate = {
    toOld: ((date) => {
        let n_date = date.split("-");
        n_date = n_date[0].length == 2 ? n_date[2] + '-' + n_date[1] + '-' + n_date[0] : n_date[0] + '-' + n_date[1] + '-' + n_date[2]

        return n_date;
    }),
    toNew: ((date) => {
        let o_date = date.split("-");
        o_date = o_date[0].length == 2 ? o_date[0] + '-' + o_date[1] + '-' + o_date[2] : o_date[2] + '-' + o_date[1] + '-' + o_date[0]

        return o_date;
    })
}

//TÍNH TUỔI 
function getAge(fromdate){ 
    let todate= new Date(); 

    var age= [], fromdate= new Date(fromdate), 
    y= [todate.getFullYear(), fromdate.getFullYear()], 
    ydiff= y[0]-y[1], 
    m= [todate.getMonth(), fromdate.getMonth()], 
    mdiff= m[0]-m[1], 
    d= [todate.getDate(), fromdate.getDate()], 
    ddiff= d[0]-d[1]; 

    //if(mdiff < 0 || (mdiff=== 0 && ddiff<0))--ydiff; 
    if(mdiff<0) mdiff+= 12; 
    if(ddiff<0){ 
     fromdate.setMonth(m[1]+1, 0); 
     ddiff= fromdate.getDate()-d[1]+d[0]; 
     --mdiff; 
    } 

    if(ydiff> 0) age = ydiff + ' tuổi'; 
    else if(mdiff> 0) age = mdiff + ' tháng'; 
    else if(ddiff> 0) age = ddiff + ' ngày'; 

    return age; 
} 

function removeVietnameseTones(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    return str;
}

//FUNCITON TÍNH BẢNG LƯƠNG
function salaryLoad(staff_data, year, month, callback) {
    //GET TONG NGAY CONG
    APIs.post('/get_cham_cong', '', {user_id: staff_data._id, month: (month + 1), year: year}, (cham_cong_data) => {
        let tong_ngay_cong = cham_cong_data.tong_ngay_cong;

        //GET SALARY TABLE HISTORY
        getSalaryHistory((salary_history) => {
            let date_filter = year + '-' + ((parseInt(month) + 1) <= 9 ? '0' + (parseInt(month) + 1) : (parseInt(month) + 1));
            let date_now = new Date().getFullYear() + '-' + (new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1);
    
            //Lọc bảng lương theo tháng và tên nhân viên
            let salary_data = salary_history.data.filter((data, i) => {
                let date_created  = data.date_created.split("T");
                date_created = date_created[0].split("-");
                date_created = date_created[0] + '-' + date_created[1];
    
                if(staff_data._id !== data.user_id) return false;
    
                return date_created == date_filter;
            })
    
            const luong_thang = Math.round(salary_data.length > 0 ? (parseInt(salary_data[0].luong_co_ban) / 28 * parseFloat(tong_ngay_cong ? tong_ngay_cong : salary_data[0].ngay_cong)) : 
                                date_filter==date_now ? (parseInt(staff_data.luong_co_ban) / 28 * (tong_ngay_cong ? tong_ngay_cong : staff_data.ngay_cong)) : 0);

            //GET BILLS
            getBill((res) => {
                if(res) {
                    getServices((dich_vu) => {
                        if(dich_vu) {
                            getGoiChup((goi_chup) => {
    
                                //Lọc thời gian của bill
                                let bills = res.filter((data, i) => {
                                    if(data.deleted) return false;
    
                                    if(data.ngay_chup) {
                                        let ngay_chup = data.ngay_chup.split("-");
                                        ngay_chup = ngay_chup[0].length !== 2 ? ngay_chup[0] + '-' + ngay_chup[1] : ngay_chup[2] + '-' + ngay_chup[1];
            
                                        return ngay_chup == date_filter;
                                    }
                                });
            
                                let total_photo = 0;
                                let total_service = 0;
                                let total_photoshop = 0;
                    
                                //Tính tổng thưởng photo và support & photosohp
                                bills.map((data, i) => {
                                    if(data.photo === staff_data._id) {
                                        data.products.map((product, i) => {
                                            let goi_chup_filter = goi_chup.map(function(e) { return e.ten_goi; }).indexOf(product.ten_goi);
                                            if(goi_chup_filter > -1) total_photo += (parseInt(product.gia) * parseInt(product.so_luong) - (product.giam_gia ? parseInt(product.giam_gia) : 0)) * (staff_data.phan_tram_thuong_goi_chup_photo) / 100
                                        });
                                    }

                                    if(data.support === staff_data._id) {
                                        data.products.map((product, i) => {
                                            let goi_chup_filter = goi_chup.map(function(e) { return e.ten_goi; }).indexOf(product.ten_goi);
                                            if(goi_chup_filter > -1) total_photo += (parseInt(product.gia) * parseInt(product.so_luong) - (product.giam_gia ? parseInt(product.giam_gia) : 0)) * (staff_data.phan_tram_thuong_goi_chup_tu_van) / 100
                                        });
                                    }

                                    if(data.photoshop === staff_data._id) {
                                        data.products.map((product, i) => {
                                            let goi_chup_filter = goi_chup.map(function(e) { return e.ten_goi; }).indexOf(product.ten_goi);
                                            if(goi_chup_filter > -1) total_photoshop += (parseInt(product.gia) * parseInt(product.so_luong) - (product.giam_gia ? parseInt(product.giam_gia) : 0)) * (staff_data.phan_tram_thuong_photoshop) / 100
                                        });
                                    }
                                })

                                //Tính tổng thưởng dịch vụ
                                bills.map((data, i) => {
                                    if(data.support === staff_data._id) {
                                        data.products.map((product, i) => {
                                            let goi_chup_filter = dich_vu.map(function(e) { return e.ten_goi; }).indexOf(product.ten_goi);
                                            if(goi_chup_filter > -1) total_service += (parseInt(product.gia) * parseInt(product.so_luong) - (product.giam_gia ? parseInt(product.giam_gia) : 0)) * (staff_data.phan_tram_thuong_dich_vu_tu_van) / 100
                                        });
                                    }
                                })
                            
                                return callback({
                                    _id: staff_data._id,
                                    ho_ten: staff_data.ho_ten,
                                    email: staff_data.email,
                                    phan_tram_thuong_goi_chup_tu_van: salary_data.length > 0 ? salary_data[0].phan_tram_thuong_goi_chup_tu_van : date_filter==date_now ? staff_data.phan_tram_thuong_goi_chup_tu_van : 0,
                                    phan_tram_thuong_goi_chup_photo: salary_data.length > 0 ? salary_data[0].phan_tram_thuong_goi_chup_photo : date_filter==date_now ? staff_data.phan_tram_thuong_goi_chup_photo : 0,
                                    phan_tram_thuong_photoshop: salary_data.length > 0 ? salary_data[0].phan_tram_thuong_photoshop : date_filter==date_now ? staff_data.phan_tram_thuong_photoshop : 0,
                                    phan_tram_thuong_dich_vu_tu_van: salary_data.length > 0 ? salary_data[0].phan_tram_thuong_dich_vu_tu_van : date_filter==date_now ? staff_data.phan_tram_thuong_dich_vu_tu_van : 0,
                                    ngay_cong: salary_data.length > 0 ? tong_ngay_cong ? tong_ngay_cong : salary_data[0].ngay_cong : date_filter==date_now ? tong_ngay_cong ? tong_ngay_cong : staff_data.ngay_cong : 0,
                                    luong_co_ban: salary_data.length > 0 ? salary_data[0].luong_co_ban : date_filter==date_now ? staff_data.luong_co_ban : 0,
                                    total_photo: total_photo,
                                    total_service: total_service,
                                    total_photoshop: total_photoshop,
                                    total: total_photo + total_service + total_photoshop,
                                    thuc_lanh: luong_thang + (total_photo + total_service + total_photoshop),
                                    luong_thang: luong_thang,
                                    date_created: date_filter
                                })
                            })
                        }
                    })
                }
            })
    
        })
    })
}


//FUNCITON TÍNH BẢNG LƯƠNG ĐÃ LOADLOAD
function salaryCalculate(staff_data, year, month, callback) {
    getSalaryHistory((salary_history) => {
        
        const luong_thang = Math.round((parseInt(staff_data.luong_co_ban) / 28 * staff_data.ngay_cong));
        let date_filter = year + '-' + ((parseInt(month) + 1) <= 9 ? '0' + (parseInt(month) + 1) : (parseInt(month) + 1));
        
        getBill((res) => {
            if(res) {
                
                getServices((dich_vu) => {
                    if(dich_vu) {
                        
                getGoiChup((goi_chup) => {

                    //Lọc thời gian của bill
                    let bills = res.filter((data, i) => {
                        if(data.deleted) return false;

                        if(data.ngay_chup) {
                            let ngay_chup = data.ngay_chup.split("-");
                            ngay_chup = ngay_chup[0].length == 2 ? ngay_chup[2] + "-" + ngay_chup[1] : ngay_chup[0] + '-' + ngay_chup[1]
                            return ngay_chup == date_filter;
                        }
                    });

                    let total_photo = 0;
                    let total_service = 0;
                    let total_photoshop = 0;
                    
                    //Tính tổng thưởng photo và support
                    bills.map((data, i) => {
                        if(data.photo === staff_data._id) {
                            data.products.map((product, i) => {
                                let goi_chup_filter = goi_chup.map(function(e) { return e.ten_goi; }).indexOf(product.ten_goi);
                                if(goi_chup_filter > -1) total_photo += (parseInt(product.gia) * parseInt(product.so_luong) - (product.giam_gia ? parseInt(product.giam_gia) : 0)) * (staff_data.phan_tram_thuong_goi_chup_photo) / 100
                            });
                        }

                        if(data.photoshop === staff_data._id) {
                            data.products.map((product, i) => {
                                let goi_chup_filter = goi_chup.map(function(e) { return e.ten_goi; }).indexOf(product.ten_goi);
                                if(goi_chup_filter > -1) total_photo += (parseInt(product.gia) * parseInt(product.so_luong) - (product.giam_gia ? parseInt(product.giam_gia) : 0)) * (staff_data.phan_tram_thuong_photoshop) / 100
                            });
                        }

                        if(data.support === staff_data._id) {
                            data.products.map((product, i) => {
                                let goi_chup_filter = goi_chup.map(function(e) { return e.ten_goi; }).indexOf(product.ten_goi);
                                if(goi_chup_filter > -1) total_photo += (parseInt(product.gia) * parseInt(product.so_luong) - (product.giam_gia ? parseInt(product.giam_gia) : 0)) * (staff_data.phan_tram_thuong_goi_chup_tu_van) / 100
                            });
                        }
                    })

                    //Tính tổng thưởng dịch vụ
                    bills.map((data, i) => {
                        if(data.support === staff_data._id) {
                            data.products.map((product, i) => {
                                let goi_chup_filter = dich_vu.map(function(e) { return e.ten_goi; }).indexOf(product.ten_goi);
                                if(goi_chup_filter > -1) total_service += (parseInt(product.gia) * parseInt(product.so_luong) - (product.giam_gia ? parseInt(product.giam_gia) : 0)) * (staff_data.phan_tram_thuong_dich_vu_tu_van) / 100
                            });
                        }
                    })
                
                    return callback({
                        _id: staff_data._id,
                        ho_ten: staff_data.ho_ten,
                        email: staff_data.email,
                        phan_tram_thuong_goi_chup_tu_van: staff_data.phan_tram_thuong_goi_chup_tu_van,
                        phan_tram_thuong_goi_chup_photo: staff_data.phan_tram_thuong_goi_chup_photo,
                        phan_tram_thuong_photoshop:staff_data.phan_tram_thuong_photoshop,
                        phan_tram_thuong_dich_vu_tu_van: staff_data.phan_tram_thuong_dich_vu_tu_van,
                        ngay_cong: staff_data.ngay_cong,
                        luong_co_ban: staff_data.luong_co_ban,
                        total_photo: total_photo,
                        total_service: total_service,
                        total_photoshop: total_photoshop,
                        total: total_photo + total_service,
                        thuc_lanh: luong_thang + (total_photo + total_service + total_photoshop),
                        luong_thang: luong_thang,
                        date_created: date_filter
                    })
                })
                    }
                })
        
            }
        })

    })
}

export {
    API,
    LINK,
    numberWithCommas,
    removeVietnameseTones,
    findWithAttr,
    salaryCalculate,
    salaryLoad,
    getAge
}