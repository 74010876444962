import React, {Component} from 'react';
import {TakePicturePackageTable} from '../components/Table';
import {TakePhotoPackagesHeader} from '../components/Header_bar';
import {TakePhotoPackageModal, TakePhotoPackageEditModal} from '../components/Modals';
import {getSingleGoiChup, updateGoiChup, getGoiChup} from '../functions/Api';
import {removeVietnameseTones} from '../functions/Global';
import {HashSpiner} from '../components/Spiners';

class TakePhotoPackages extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            modal_is_open: "false",
            edit_modal_is_open: "false",
            edit_id: null,
            data_goi_chup: null,
            loading: false
        }

        getGoiChup((res) => {
          this.setState ({
            data_goi_chup: res
          })
        })
    }

    handleCloseModal = () => {
        this.updateData();
    }

    handleOpenModal = () => {
        this.setState({
            modal_is_open: "true"
        })
    }

    handleOpenEditModal = (id) => {
        getSingleGoiChup(id, (res) => {
            this.setState ({
                data: res,
                edit_modal_is_open: "true",
                edit_id: id
            })
        });
    }

    //THỰC HIỆN SEARCH
    handleSearch = (search_value) => {
        //RELOAD DATA IF SEARCH VALUE IS NULL
        if(search_value === "") {
            getGoiChup((res) => {
                this.setState({
                    data_goi_chup: res
                })            
            })
        }

        //RELOAD DATA
        getGoiChup((res) => {
            let data = res.reverse()
  
            //TÌM KHÁCH HÀNG OR TÊN BÉ OR SÔS ĐIỆN THOẠI
            let filtered_data = data.filter((data, i) => {
                if(removeVietnameseTones(data.ten_goi).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true;  
                
                return false;
            })
  
            this.setState({
                data_goi_chup: filtered_data
            })            
        })
    }

    updateData = () => {
      getGoiChup((res) => {
        this.setState ({
            data_goi_chup: res,
            modal_is_open: "false",
            edit_modal_is_open: "false",
            loading: false
        })
      })
    }

    render() {
        if(!this.state.data_goi_chup) {
            return(<div className="load_view"><HashSpiner/></div>)
        }
        return(
            <div>
                <TakePhotoPackagesHeader handleOpenModal={this.handleOpenModal} onSearchKeyPress={this.handleSearch}/>
                <TakePicturePackageTable data={this.state.data_goi_chup} ref="table" handleOpenModal={this.handleOpenEditModal}/>
                <TakePhotoPackageModal onLoading={() => this.setState({loading: false})} is_open={this.state.modal_is_open} handleCloseModal={this.handleCloseModal}/>
                <TakePhotoPackageEditModal data={this.state.data} is_open={this.state.edit_modal_is_open} edit_id={this.state.edit_id} handleCloseModal={this.handleCloseModal}/>
            </div>
        )
    }
}

export default TakePhotoPackages;