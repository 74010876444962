import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import "../styles/components/Side_bar.scss";

import {BsPeople, BsCalendar, BsCardList, BsCreditCard, BsInboxes, BsClipboardData, BsList, BsArrowReturnRight} from 'react-icons/bs';
import {FiCamera, FiSettings, FiLogOut, FiTrash2} from 'react-icons/fi';
import {AiOutlineRest} from 'react-icons/ai';

import {getPermissions} from '../functions/Api';
import { findWithAttr } from '../functions/Global';

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: window.location.pathname,
            perms: null,
            side_bar: false
        }
        
        getPermissions((res) => {
            this.setState({
                perms: res
            })
        });
    }

    handleToggleSideBar = () => {
        localStorage.setItem("side_bar_toggle", !this.state.side_bar);
        this.setState({
            side_bar: !this.state.side_bar
        })
    }

    render() {
        if(!this.state.perms) {
            return(
                <div></div>
            )
        }
        
        const perms = this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons;

        if(!this.state.side_bar) {
            return(
                <div className="side_bar_container min">
                    <div className="side_bar"> 
                    <div className='item avatar_container'>
                        <button className="menu_btn" onClick={this.handleToggleSideBar}><BsList className="icon"/></button>
                    </div>

                    {perms.bill[0] == "1" ? 
                        <Link to='/' className={this.state.location === '/' ? 'item active' : 'item'} onClick={() => this.setState({location:'/', side_bar: false})}>
                            <BsCardList className='icon'/>
                            <p className="item_name">Đơn hàng</p>
                        </Link> : null}
    
                    {perms.calendar[0] == "1" ? 
                        <Link to='/calendar' className={this.state.location === '/calendar' ? 'item active' : 'item'} onClick={() => this.setState({location:'/calendar', side_bar: false})}>
                            <BsCalendar className='icon'/>
                            <p className="item_name">Lịch</p>
                        </Link> : null}

                    {perms.customers[0] == "1" ? 
                    <Link to='/customers' className={this.state.location === '/customers' ? 'item active' : 'item'} onClick={() => this.setState({location:'/customers', side_bar: false})}>
                        <BsPeople className='icon'/>
                        <p className="item_name">Khách hàng</p>
                    </Link> : null}

                    {perms.spending[0] == "1" ? 
                    <Link to='/spending' className={this.state.location === '/spending' ? 'item active' : 'item'} onClick={() => this.setState({location:'/spending', side_bar: false})}>
                        <BsCreditCard className='icon'/>
                        <p className="item_name">Chi</p>
                    </Link> : null}

                    {perms.goi_chup[0] != "1" && perms.services[0] != "1" ? null : 
                        <div className="menu_header">
                            <hr/>
                        </div>
                    }
                    {perms.goi_chup[0] == "1" ? 
                    <Link to='/take_picture_packages' className={this.state.location === '/take_picture_packages' ? 'item active' : 'item'} onClick={() => this.setState({location:'/take_picture_packages', side_bar: false})}>
                        <FiCamera className='icon'/>
                        <p className="item_name">Gói chụp</p>
                    </Link> : null}

                    {perms.services[0] == "1" ? 
                    <Link to='/services' className={this.state.location === '/services' ? 'item active' : 'item'} onClick={() => this.setState({location:'/services', side_bar: false})}>
                        <AiOutlineRest className='icon'/>
                        <p className="item_name">Dịch vụ</p>
                    </Link> : null}

                    {perms.total_analytics[0] != "1" && perms.total_analytics[0] != "1" ? null : 
                        <div className="menu_header">
                            <hr/>
                        </div>
                    }

                    {perms.total_analytics[0] == "1" ? 
                    <Link to='/total_revenue' className={this.state.location === '/total_revenue' ? 'item active' : 'item'} onClick={() => this.setState({location:'/total_revenue', side_bar: false})}>
                        <BsClipboardData className='icon'/>
                        <p className="item_name">Báo cáo doanh thu</p>
                    </Link> : null}
                    {perms.spending_analytics[0] == "1" ? 
                    <Link to='/realtime_revenue' className={this.state.location === '/realtime_revenue' ? 'item active' : 'item'} onClick={() => this.setState({location:'/realtime_revenue', side_bar: false})}>
                        <BsClipboardData className='icon'/>
                        <p className="item_name">Báo cáo thu chi</p>
                    </Link> : null}
                    <div className="menu_header">
                            <hr/>
                    </div>
                    
                    {perms.trash[0] == "1" ? 
                        <Link to='/trash' className={this.state.location === '/trash' ? 'item active' : 'item'} onClick={() => this.setState({location:'/trash', side_bar: false})}>
                            <FiTrash2 className='icon'/>
                            <p className="item_name">Thùng rác</p>
                        </Link> : null}
                    
                    <Link to='/settings' className={this.state.location === '/settings' ? 'item active' : 'item'} onClick={() => this.setState({location:'/settings', side_bar: false})}>
                        <FiSettings className='icon'/>
                        <p className="item_name">Cài đặt</p>
                    </Link>
                    <Link to='/logout' className={this.state.location === '/logout' ? 'item active' : 'item'} onClick={() => this.setState({location:'/logout', side_bar: false})}>
                        <FiLogOut className='icon'/>
                        <p className="item_name">Đăng xuất</p>
                    </Link>
                </div>
                </div>
            )
        }

        return(
        <div className="side_bar_container">
            <div className="side_bar">
            <div className='item avatar_container'>
                <button className="menu_btn" onClick={this.handleToggleSideBar}><BsList className="icon"/></button>
                <p className='user_name'>{localStorage.getItem('ho_ten')}</p>
            </div>

            {perms.bill[0] != "1" && perms.calendar[0] != "1" && perms.customers[0] != "1" ? null : 
                <div className="menu_header">
                    <span>Công Việc</span>
                </div>
            }
            
            {perms.bill[0] == "1" ? 
                <Link to='/' className={this.state.location === '/' ? 'item active' : 'item'} onClick={() => this.setState({location:'/', side_bar: false})}>
                    <BsCardList className='icon'/>
                    <p className="item_name">Đơn hàng</p>
                </Link> : null}
                
            {perms.calendar[0] == "1" ? 
                <Link to='/calendar' className={this.state.location === '/calendar' ? 'item active' : 'item'} onClick={() => this.setState({location:'/calendar', side_bar: false})}>
                    <BsCalendar className='icon'/>
                    <p className="item_name">Lịch</p>
                </Link> : null}
            
            {perms.customers[0] == "1" ? 
            <Link to='/customers' className={this.state.location === '/customers' ? 'item active' : 'item'} onClick={() => this.setState({location:'/customers', side_bar: false})}>
                <BsPeople className='icon'/>
                <p className="item_name">Khách hàng</p>
            </Link> : null}

            {perms.spending[0] == "1" ? 
            <Link to='/spending' className={this.state.location === '/spending' ? 'item active' : 'item'} onClick={() => this.setState({location:'/spending', side_bar: false})}>
                <BsCreditCard className='icon'/>
                <p className="item_name">Chi</p>
            </Link> : null}
            
            <br/>
            
            {perms.goi_chup[0] != "1" && perms.services[0] != "1" ? null : 
                <div className="menu_header">
                    <span>Sản Phẩm</span>
                </div>
            }
            {perms.goi_chup[0] == "1" ? 
            <Link to='/take_picture_packages' className={this.state.location === '/take_picture_packages' ? 'item active' : 'item'} onClick={() => this.setState({location:'/take_picture_packages', side_bar: false})}>
                <FiCamera className='icon'/>
                <p className="item_name">Gói chụp</p>
            </Link> : null}
            
            {perms.services[0] == "1" ? 
            <Link to='/services' className={this.state.location === '/services' ? 'item active' : 'item'} onClick={() => this.setState({location:'/services', side_bar: false})}>
                <AiOutlineRest className='icon'/>
                <p className="item_name">Dịch vụ</p>
            </Link> : null}
            
            <br/>
            
            {perms.total_analytics[0] != "1" && perms.total_analytics[0] != "1" ? null : 
                <div className="menu_header">
                    <span>Báo Cáo</span>
                </div>
            }
            
            {perms.total_analytics[0] == "1" ? 
            <Link to='/total_revenue' className={this.state.location === '/total_revenue' ? 'item active' : 'item'} onClick={() => this.setState({location:'/total_revenue', side_bar: false})}>
                <BsClipboardData className='icon'/>
                <p className="item_name">Báo cáo doanh thu</p>
            </Link> : null}
            {perms.realtime_analytics[0] == "1" ? 
            <Link to='/realtime_revenue' className={this.state.location === '/realtime_revenue' ? 'item active' : 'item'} onClick={() => this.setState({location:'/realtime_revenue', side_bar: false})}>
                <BsClipboardData className='icon'/>
                <p className="item_name">Báo cáo thu chi</p>
            </Link> : null}

            <br/>
            <div className="menu_header">
                <span>Khác</span>
            </div>
                    
            {perms.trash[0] == "1" ? 
            <Link to='/trash' className={this.state.location === '/trash' ? 'item active' : 'item'} onClick={() => this.setState({location:'/trash', side_bar: false})}>
                <FiTrash2 className='icon'/>
                <p className="item_name">Thùng rác</p>
            </Link> : null}
            
            <Link to='/settings' className={this.state.location === '/settings' ? 'item active' : 'item'} onClick={() => this.setState({location:'/settings', side_bar: false})}>
                <FiSettings className='icon'/>
                <p className="item_name">Cài đặt</p>
            </Link>
            <Link to='/logout' className={this.state.location === '/logout' ? 'item active' : 'item'} onClick={() => this.setState({location:'/logout', side_bar: false})}>
                <FiLogOut className='icon'/>
                <p className="item_name">Đăng xuất</p>
            </Link>
        </div>
        </div>
        )
    }
}

export default SideBar;