import React, {Component} from 'react';
import {APIs, getPermissions} from '../functions/Api';
import {DefaultHeaderBar} from '../components/Header_bar';
import {HashSpiner} from '../components/Spiners';
import {ChamCongTable, TabBar} from '../components/ChamCongTable';
import { findWithAttr} from '../functions/Global';

class ChamCong extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            modal_is_open: "false",
            edit_modal_is_open: "false",
            edit_id: null,
            data_spending: null,
            perms: null,
            month: [1,2,3,4,5,6,7,8,9,10,11,12],
            date: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
            tab: new Date().getMonth(),
            sang: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
            year: new Date().getFullYear(),
            months: new Date().getMonth() + 1,
            chieu: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        }

        APIs.post('/get_cham_cong','', {user_id: localStorage.getItem('user_id'), month: new Date().getMonth() + 1, year: new Date().getFullYear()}, (res) => {
            this.setState({
                sang: res.sang ? res.sang : this.state.sang,
                chieu: res.chieu ? res.chieu : this.state.chieu,
            })
        })

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        })
    }

    onTabChange = (tab) => {   
        this.setState ({
            tab_data: null,
            tab: tab,
            months: (tab + 1),
            date: null
        })     

        APIs.post('/get_cham_cong','', {user_id: localStorage.getItem('user_id'), month: tab + 1, year: this.state.year}, (res) => {
            this.setState({
                sang: res.sang ? res.sang : this.state.sang,
                chieu: res.chieu ? res.chieu : this.state.chieu,
                date: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
            })
        })
    }

    //YEAR FILTER
    handleFilter = (date) => {
        let year = date.split("-")[0];
        let month = new Date(date).getMonth() + 1;

        this.setState ({
            date: null
        })  

        APIs.post('/get_cham_cong','', {user_id: localStorage.getItem('user_id'), month: month, year: year}, (res) => {
            this.setState({
                sang: res.sang ? res.sang : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
                chieu: res.chieu ? res.chieu : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
                year: year,
                months: month,
                date: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
            })
        })
    }

    render() {
        if(!this.state.perms) {
            return(<div className="load_view"><HashSpiner/></div>)
        }

        return(
            <div className="view">
                <DefaultHeaderBar type='1' onDropdownFilterSalaryBtnClick={this.handleFilter} handleSalaryFilter={this.handleFilter} title="Chấm Công" onGoBack={() => this.props.history.goBack()} handleCreate={this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.spending[1] == "1" ? this.handleOpenModal : null} onSearchKeyPress={this.handleSearch}/>
                <ChamCongTable user_id={localStorage.getItem('user_id')} month={this.state.month} months={this.state.months} year={this.state.year} sang={this.state.sang} chieu={this.state.chieu} date={this.state.date}/>
            </div>
        )
    }
}

export default ChamCong;