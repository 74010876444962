import React,{ Component } from 'react';
import {SettingsHeader} from '../components/Header_bar';
import {Link} from 'react-router-dom';
import { findWithAttr} from '../functions/Global';

import "../styles/views/Settings.scss";

import {BsPeople, BsAppIndicator, BsInboxes, BsBuilding, BsCreditCard, BsFillArchiveFill, BsPersonCheck, BsFolderCheck} from 'react-icons/bs';

import {getPermissions} from '../functions/Api';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            perms: null
        } 

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        })
    }

    render() {
        if(!this.state.perms) {
            return(<div></div>)
        }
        return(
            <div className="settings_view">
                <SettingsHeader/>
                <div className="settings_container">
                    <Link to="/settings/personnel" className="setting_card">
                        <div className="icon_container">
                            <BsPeople className="icon"/>
                        </div>
                        <span className="setting_title">Nhân Sự</span>
                    </Link>
                    {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.branch[0] == "1" ? 
                    <Link to="/branch" className="setting_card">
                        <div className="icon_container">
                            <BsBuilding className="icon"/>
                        </div>
                        <span className="setting_title">Chi Nhánh</span>
                    </Link> : null}
                    
                    {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.status[0] == "1" ?
                    <Link to="/status" className="setting_card">
                        <div className="icon_container">
                            <BsAppIndicator className="icon"/>
                        </div>
                        <span className="setting_title">Tình Trạng</span>
                    </Link> : null}
                    
                    {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.backup[0] == "1" ?
                    <Link to="/backup" className="setting_card">
                        <div className="icon_container">
                            <BsFillArchiveFill className="icon"/>
                        </div>
                        <span className="setting_title">Sao Lưu</span>
                    </Link> : null}
                </div>
            </div>
        )
    }
}

export default Settings;