import React, {Component} from 'react';
import {DefaultHeaderBar} from '../components/Header_bar';
import {BranchTable} from '../components/Table';
import {BranchModal, BranchEditModal} from '../components/Modals';
import {getBranch, getSingleBranch, getPermissions} from '../functions/Api';
import {removeVietnameseTones, findWithAttr} from '../functions/Global';
import {HashSpiner} from '../components/Spiners';

class Branch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_modal_open: "false",
            is_edit_modal_open :"false",
            data: null,
            edit_data: null,
            edit_id: null,
            perms:null
        }

        getBranch((res) => {
            this.setState ({
                data: res
            })
        });

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        })
    }

    handleOpenCreateModal = () => {
        this.setState ({
            is_modal_open: "true"
        })
    }

    handleOpenEditModal = (id, i) => {
        getSingleBranch(id, (res) => {
            this.setState ({
                edit_data: res,
                edit_id: i,
                is_edit_modal_open: "true"
            })
        });
    }

    handleCloseModal = () => {
        this.setState ({
            is_modal_open: "false",
            is_edit_modal_open :"false",
            edit_data: null
        })
    }

    handleUpdateData = () => {
        getBranch((res) => {
            this.setState ({
                data: res,
                is_modal_open: "false",
                is_edit_modal_open :"false",
                edit_data: null
            })
        });
    }

    //THỰC HIỆN SEARCH
    handleSearch = (search_value) => {
        //RELOAD DATA IF SEARCH VALUE IS NULL
        if(search_value === "") {
            getBranch((res) => {
                this.setState({
                    data: res
                })            
            })
        }

        //RELOAD DATA
        getBranch((res) => {
            let data = res.reverse()
  
            //TÌM KHÁCH HÀNG OR TÊN BÉ OR SÔS ĐIỆN THOẠI
            let filtered_data = data.filter((data, i) => {
                if(removeVietnameseTones(data.ten_chi_nhanh).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true;  
                if(removeVietnameseTones(data.dia_chi).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true;  
                
                return false;
            })
  
            this.setState({
                data: filtered_data
            })            
        })
    }

    updateData = () => {
        getBranch((res) => {
        this.setState ({
            data: res
        })
      })
    }

    render() {
        if(!this.state.data || !this.state.perms) {
            return(<div className="load_view"><HashSpiner/></div>)
        }
        
        return(
            <div>
                <DefaultHeaderBar  onGoBack={() => this.props.history.goBack()} title="Chi Nhánh" onSearchKeyPress={this.handleSearch} handleEdit={null} create_btn_link = {null} handleRemove={null} handleSave={null} handleCreate={this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.branch[1] == "1" ? this.handleOpenCreateModal : null}/>
                <BranchTable data={this.state.data} onClick={this.handleOpenEditModal}/>
                <BranchEditModal edit_id={this.state.edit_id} handleSave={this.handleUpdateData} data={this.state.edit_data} is_open={this.state.is_edit_modal_open}  handleCloseModal={this.handleCloseModal}/>
                <BranchModal is_open={this.state.is_modal_open} handleSave={this.handleUpdateData} handleCloseModal={this.handleCloseModal}/>
            </div>
        )
    }
}

export default Branch;