import React, {Component, useEffect} from 'react';
import {BillServicesModal, BillServicesEditModal} from '../components/Modals';
import {DefaultHeaderBar} from '../components/Header_bar';
import {BillForm, ProductListForm} from '../components/Forms';
import {BillEditProductTable} from '../components/Table';
import { findWithAttr} from '../functions/Global';
import {LINK} from '../functions/Global';

import {createBill, updateBill, getSingleBill, removeBill, getPermissions, restoreBill, getSingleStaff} from '../functions/Api';
import {Redirect} from 'react-router-dom';
import socketIOClient from 'socket.io-client';

import "../styles/views/Bill_Edit.scss";

const socket = socketIOClient(LINK);    

class BillEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
           bill_data : null,
           product_data: [],
           products: {"products": []},
           product_total: 0,
           edit_data: null,
           data: null,
           is_modal_open: "false",
           is_edit_modal_open: "false",
           redirect: false,
           perms: null,
           date: this.props.match.params.date,
           print_bill: false,
           user_edited_name: null,
           is_editting: false,
           editing_id: null
        }

        if(this.props.match.params.id !== "create_new") {
            getSingleBill(this.props.match.params.id, (res) => {
                if(res.user_edited_id) {
                    getSingleStaff(res.user_edited_id, user => {
                        this.setState ({
                            user_edited_name: `Được chỉnh sửa bởi ${user.ho_ten}`
                        })
                    })
                }

                this.setState ({
                    data: res,
                    products: {"products": res.products},
                    product_total: res.tong,
                    editing_id: this.props.match.params.id,
                    is_editting: res.deleted ? false : true
                })
            });
        }
        
        getPermissions((res) => {
            this.setState({
                perms: res
            })
        });
    }

    handleSaveOnclick = async () => {
        await this.refs.product_list_form.handleSave();
        await this.refs.bill_form.handleSave();
    }

    handleSave = async (data) => {
        await this.setState ({
            bill_data: data
        })

        await this.handleSubmit();
    }

    handleSaveProduct = async (data) => {
        await this.setState ({
            product_data: data
        })
    }

    handleSaveProductTab = (data) => {
        let new_data = this.state.products;
        let total = 0;
        new_data.products.push(data);

        new_data.products.map((data, i) => {
            total += (parseInt(data.gia) * parseInt(data.so_luong) * ((100 - parseInt(data.chiet_khau))/100)) - (data.giam_gia ? parseInt(data.giam_gia) : 0);
        });

        this.setState({
            products: new_data,
            product_total: total
        })

        this.refs.product_list_form.handleUpdateConNo(data);
        this.handleCloseAddProductModal();
    }

    handleUpdateProductTab = (data, id) => {
        let new_data = this.state.products.products;

        //THAY ĐỔI GIÁ TRỊ INPUT CÒN NỢ
        this.refs.product_list_form.handleUpdateConNoAfterChangeProduct(new_data[id], data);

        let total = 0;
        new_data[id] = data;

        new_data.map((data, i) => {
            total += (parseInt(data.gia) * parseInt(data.so_luong) * ((100 - parseInt(data.chiet_khau))/100) - parseInt(data.giam_gia ? data.giam_gia : 0));
        });

        this.setState({
            products: {"products": new_data},
            product_total: total
        })
        
        this.handleCloseAddProductModal();
    }

    handleRemoveProductTab = (id, datas) => {
        let data = this.state.products.products.filter((data, i) => {return i !== id});
        let data_2 = datas;
        data_2["gia"] = parseInt('-' +  data_2["gia"]);

        //THAY ĐỔI GIÁ TRỊ INPUT CÒN NỢ
        this.refs.product_list_form.handleUpdateConNo(data_2);
        
        let total = 0;

        data.map((data, i) => {
            total += parseInt(data.gia) * parseInt(data.so_luong) * ((100 - parseInt(data.chiet_khau))/100);
        })

        this.setState({
            products: {"products": data},
            product_total: total
        })
        
       // this.refs.product_list_form.handleUpdateConNo(data_2);
        this.handleCloseAddProductModal();
    }

    //SAVE OR TẠO ĐƠN HÀNG
    handleSubmit = () => {
        let data = Object.assign(this.state.bill_data,this.state.product_data, this.state.products);

        if(this.props.match.params.id !== "create_new" || this.state.is_editting) {
            data["_id"] = this.state.editing_id;
            data["user_edited_id"] = localStorage.getItem("user_id");
            data["date_created"] = this.state.data.date_created;

            updateBill(data, (res) => {
                socket.emit("client-update-bill", data);//EMIT TO UPDATE CALENDAR
                alert("Lưu đơn hàng thành công!");
            });
        }else {
            createBill(data, (res) => {
                data["user_edited_id"] = localStorage.getItem("user_id");

                socket.emit("client-create-bill", res.data);//EMIT TO UPDATE CALENDAR
                alert("Tạo đơn hàng thành công!");

                this.setState({
                    is_editting: true,
                    data: res.data,
                    products: {"products": res.data.products},
                    product_total: res.data.tong,
                    editing_id: res.data._id
                })
            });
        }
    }

    handleOpenAddProductModal = () => {
        this.setState ({
            is_modal_open: "true"
        })
    }

    handleOpenEditProductModal = (id) => {
        let data = this.state.products.products;
        data[id]["id"] = id;
        data = data[id];

        this.refs.bill_services_edit_modal.state.giam_gia = data.giam_gia ? data.giam_gia : 0;
        this.refs.bill_services_edit_modal.state.so_luong = data.so_luong;
        this.refs.bill_services_edit_modal.state._id = data._id;
        this.refs.bill_services_edit_modal.state.id = id;

        this.setState ({
            is_edit_modal_open: "true",
            edit_data: data
        })
    }

    handleCloseAddProductModal = () => {
        this.setState ({
            is_modal_open: "false",
            is_edit_modal_open: "false"
        })
    }

    handleRemove = () => {
        removeBill(this.props.match.params.id, (res) => {
            socket.emit("client-remove-bill", {id: this.props.match.params.id}); //EMIT TO SERVER WHEN CLIENT REMOVE BILL

            this.setState({
                redirect: true
            })
        });
    }

    handlePrintBill = () => {
        this.setState({
            print_bill: true
        })
    }

    handleRestore = () => {
        restoreBill(this.props.match.params.id, (res) => {
            this.setState({
                redirect: true
            })
        });
    }

    render() {
        if(!this.state.data && this.props.match.params.id !== "create_new" || !this.state.perms) {
            return(<div></div>)
        }

        if(this.state.redirect) {
            if(window.location.pathname.split('/')[1] === "bill_restore") {
                return <Redirect to="/trash"/>
            }
            return <Redirect to="/"/>
        }

        if(this.state.print_bill) {
            return <Redirect to={`/bill_print/${this.state.editing_id}`}/>
        }
 
        return(
            <div className="bill_edit_view">
                {!this.state.is_editting ? this.props.match.params.id == "create_new" ? 
                <DefaultHeaderBar customTitle={this.state.user_edited_name}  onGoBack={() => this.props.history.goBack()} title="Đơn Hàng" back_link="/" handleEdit={null} handleCreate={null} create_btn_link={null} handleRemove={null} handleSave={this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[1] == "1" ? this.handleSaveOnclick : null} handleAddProduct={this.handleOpenAddProductModal}/> 
                : <DefaultHeaderBar customTitle={this.state.user_edited_name} onGoBack={() => this.props.history.goBack()} handleRestore={this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.trash[2] == "1" ? this.handleRestore : null} handleEdit={null} handleCreate={null} create_btn_link={null} handleRemove={this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.trash[3] == "1" ? this.handleRemove : null} title="Thùng Rác" back_link="/"/> 
                : <DefaultHeaderBar customTitle={this.state.user_edited_name} handlePrintBill={this.handlePrintBill}  onGoBack={() => this.props.history.goBack()} handleEdit={null} handleCreate={null} create_btn_link={null} handleRemove={this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[3] == "1" ? this.handleRemove : null} title="Đơn Hàng" back_link="/" handleSave={this.handleSaveOnclick} handleAddProduct={this.handleOpenAddProductModal}/>}
                <BillForm date={this.state.date} perms={this.state.perms} ref="bill_form" onSave={this.handleSave} data={this.state.data}/>
                <BillServicesModal is_open={this.state.is_modal_open} onClose={this.handleCloseAddProductModal} onSave={this.handleSaveProductTab}/>
                <BillServicesEditModal data={this.state.edit_data} ref="bill_services_edit_modal" perms={this.state.perms} onRemove={this.handleRemoveProductTab} is_open={this.state.is_edit_modal_open} onClose={this.handleCloseAddProductModal} onSave={this.handleUpdateProductTab}/>

                <h1 className="view_header" style={{marginTop: "64px", marginLeft: "16px"}}>Danh Sách Sản Phẩm</h1>
    
                <div className="product_list_container" style={{display: "fles"}}>
                    <BillEditProductTable data={this.state.products.products} onEdit={this.handleOpenEditProductModal}/>
                    <ProductListForm perms={this.state.perms}  ref="product_list_form" data={this.state.data} product_total={this.state.product_total} onSave={this.handleSaveProduct}/>
                </div>
            </div>
        )
    }
}   

export default BillEdit;