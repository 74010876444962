import React, {Component} from 'react';
import {ChamCongTable, TabBar} from '../components/ChamCongTable';
import {APIs, getPermissions} from '../functions/Api';
import {DefaultHeaderBar} from '../components/Header_bar';
import { findWithAttr} from '../functions/Global';

class ChamCongManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            month: [1,2,3,4,5,6,7,8,9,10,11,12],
            date: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
            tab: new Date().getMonth(),
            sang: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
            year: new Date().getFullYear(),
            months: parseInt(new Date().getMonth()) + 1,
            chieu: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
            perms: null
        }

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        });

        APIs.post('/get_cham_cong','', {user_id: this.props.match.params.id, month: new Date().getMonth() + 1, year: new Date().getFullYear()}, (res) => {
            this.setState({
                sang: res.sang ? res.sang : this.state.sang,
                chieu: res.chieu ? res.chieu : this.state.chieu,
            })
        })
    }

    onTabChange = (tab) => {   
        this.setState ({
            tab_data: null,
            tab: tab,
            months: (tab + 1),
            date: null
        })     

        APIs.post('/get_cham_cong','', {user_id: this.props.match.params.id, month: tab + 1, year: new Date().getFullYear()}, (res) => {
            this.setState({
                sang: res.sang ? res.sang : this.state.sang,
                chieu: res.chieu ? res.chieu : this.state.chieu,
                date: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
            })
        })
    }

    //YEAR FILTER
    handleFilter = (date) => {
        let year = date.split("-")[0];
        let month = new Date(date).getMonth() + 1;

        this.setState ({
            date: null
        })  

        APIs.post('/get_cham_cong','', {user_id: this.props.match.params.id, month: month, year: year}, (res) => {
            this.setState({
                sang: res.sang ? res.sang : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
                chieu: res.chieu ? res.chieu : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
                year: year,
                months: month,
                date: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
            })
        })
    }
    
    render() {
        if(!this.state.perms) {
            return(<div></div>)
        }

        return(
            <div className="view">
                <DefaultHeaderBar create_btn_link={null} onDropdownFilterSalaryBtnClick={this.handleFilter} handleSalaryFilter={this.handleFilter} onDropdownFilterSalaryBtnClick={this.handleFilter} handleSalaryFilter={this.handleFilter} handleRemove={ null }  onGoBack={() => this.props.history.goBack()} title="Lương Nhân Viên" handleSave={this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.staff_salary[2] == "1" ? this.handleSave: null}/>
                <ChamCongTable user_id={this.props.match.params.id} month={this.state.month} months={this.state.months} year={this.state.year} sang={this.state.sang} chieu={this.state.chieu} date={this.state.date}/>
            </div>
        )
    }
}

export default ChamCongManagement;