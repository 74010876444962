import React, {Component} from 'react';
import {BsBoxArrowInDown, BsFiles} from 'react-icons/bs';
import {DefaultHeaderBar} from '../components/Header_bar';
import Axios from 'axios';
import {API} from '../functions/Global';
import {Redirect} from 'react-dom';

class Backup extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    handleRestore = (e) => {
        var formData = new FormData();
        var backupFile = e.target.files[0];

        formData.append("file", backupFile);

        //POST TO SERVER
        Axios.post(`${API}/restore`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            alert("Restore Dữ Liệu Thành Công!");
        });
    }

    render() {

        return(
            <div className="settings_view">
                {localStorage.getItem('chuc_vu') ? <DefaultHeaderBar onGoBack={() => this.props.history.goBack()} title="Sao Lưu"/> : null}
                <div className="settings_container">
                    <a className="setting_card" href={`${API}/create_backup`} target="_download" style={{cursor: 'pointer'}}>
                        <div className="icon_container">
                            <BsFiles className="icon"/>
                        </div>
                        <span className="setting_title">Tạo Bản Sao Lưu</span>
                    </a>

                    <label className="setting_card" htmlFor="backup_file" style={{display: 'flex', cursor: "pointer"}}>
                        <div className="icon_container">
                            <BsBoxArrowInDown className="icon"/>
                        </div>
                        <span className="setting_title">Nhập Dữ Liệu</span>
                    </label>
                    <input type="file" name="backup_file" id="backup_file" style={{display: 'none'}} onChange={this.handleRestore}/>
                </div>
            </div>
        )
    }
}

export default Backup;