import React, {Component} from 'react';
import {DefaultHeaderBar} from '../components/Header_bar';
import {Link} from 'react-router-dom';
import { findWithAttr} from '../functions/Global';

import {BsPeople, BsAppIndicator, BsInboxes, BsBuilding, BsCreditCard, BsFillArchiveFill, BsPersonCheck, BsFolderCheck} from 'react-icons/bs';

import {getPermissions} from '../functions/Api';

export default class Personnel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            perms: null
        } 

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        })
    }

    render() {
        if(!this.state.perms) {
            return(<div></div>)
        }

        return(
            <div className="settings_view">
                {localStorage.getItem('chuc_vu') ? <DefaultHeaderBar onGoBack={() => this.props.history.goBack()} title="Nhân Sự"/> : null}
                <div className="settings_container">
                    {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.staff[0] == "1" ? 
                    <Link to="/staff" className="setting_card">
                        <div className="icon_container">
                            <BsPeople className="icon"/>
                        </div>
                        <span className="setting_title">Nhân Viên</span>
                    </Link> : null}
                    
                    {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.staff_salary[0] == "1" ?
                    <Link to="/staff_salary" className="setting_card">
                        <div className="icon_container">
                            <BsCreditCard className="icon"/>
                        </div>
                        <span className="setting_title">Lương Nhân Viên</span>
                    </Link> : null}
                    
                    <Link to="/cham_cong" className="setting_card">
                        <div className="icon_container">
                            <BsPersonCheck className="icon"/>
                        </div>
                        <span className="setting_title">Chấm Công</span>
                    </Link>
                    
                    {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.permisisons[0] == "1" ? 
                    <Link to="/permission" className="setting_card">
                        <div className="icon_container">
                            <BsInboxes className="icon"/>
                        </div>
                        <span className="setting_title">Chức Năng Truy Cập</span>
                    </Link> : null}
                    
                    {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.cham_cong[0] == "1" ? 
                    <Link to="/cham_cong_list" className="setting_card">
                        <div className="icon_container">
                            <BsFolderCheck className="icon"/>
                        </div>
                        <span className="setting_title">Quản Lý Chấm Công</span>
                    </Link> : null}
                </div>
            </div>
        )
    }
}