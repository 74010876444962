import React, {Component} from 'react';
import {FiChevronLeft, FiChevronRight} from 'react-icons/fi';
import "./styles.scss";
import {calendar} from './functions';
import {findWithAttr} from '../../functions/Global';
import {getStatus} from '../../functions/Api';
import {FiPlus} from 'react-icons/fi';
import {Link} from 'react-router-dom';

function compare(a,b) {
    var time1 = parseFloat(a.gio_chup.replace(':','.').replace(/[^\d.-]/g, ''));
    var time2 = parseFloat(b.gio_chup.replace(':','.').replace(/[^\d.-]/g, ''));
    if (time1 < time2) return -1;
    if (time1 > time2) return 1;
    return 0;
} 

class CalendarHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear()
        }
    }

    handleNavigate = (type) => {
        if(type === 'next') {
            if(this.state.month === 12) {
                this.setState({
                    month: 1,
                    year: parseInt(this.state.year) + 1
                })

                this.props.onNavigate(1, parseInt(this.state.year) + 1);
            }else{
                this.setState({
                    month: parseInt(this.state.month) + 1
                })

                this.props.onNavigate(parseInt(this.state.month) + 1, this.state.year);
            }
        }else {
            if(this.state.month === 1) {
                this.setState({
                    month: 12,
                    year: parseInt(this.state.year) - 1
                })

                this.props.onNavigate(12, parseInt(this.state.year) - 1);
            }else{
                this.setState({
                    month: parseInt(this.state.month) - 1
                })

                this.props.onNavigate(parseInt(this.state.month) - 1, this.state.year);
            }
        }
    }   

    render() {
        return(
            <div className="calendar_header">
                <div className="control_container">
                    <button onClick={() => this.handleNavigate('prev')}><FiChevronLeft className="icon"/></button>
                    <h1>Tháng {this.state.month} - {this.state.year}</h1>
                    <button onClick={() => this.handleNavigate('next')}><FiChevronRight className="icon"/></button>
                </div>
                <div className="date_columns">
                    <div className="column"><span>THỨ 2</span></div>
                    <div className="column"><span>THỨ 3</span></div>
                    <div className="column"><span>THỨ 4</span></div>
                    <div className="column"><span>THỨ 5</span></div>
                    <div className="column"><span>THỨ 6</span></div>
                    <div className="column"><span>THỨ 7</span></div>
                    <div className="column"><span>C. NHẬT</span></div>
                </div>
            </div>
        )
    }
}

class CalendarBlock extends Component {
    render() {
        let events = this.props.events.filter((data, i) => {
            let date = data.ngay_chup.split("-");
            if(date[2] != this.props.year || date[1] != this.props.month || date[0] != this.props.date) return false;

            return true;
        })

        events.sort(compare)
        return(
            <div className={`calendar_block ${this.props.focus_date == this.props.date ? 'focus' : ''}`} onClick={this.props.onBlockClick}>
                <div className={new Date().getDate() == this.props.date && new Date().getMonth() + 1 == this.props.month && new Date().getFullYear() == this.props.year ? 'date_container active' : 'date_container'}>
                    <span>{this.props.date !== 0 ? this.props.date : null}</span>
                </div>
                {this.props.events ?    
                    <div className="events_container">
                        <div className={`container`}>
                            {events.map((data, i) => {
                                return(
                                    <div className={`event ${findWithAttr(this.props.branch,'ten_chi_nhanh',data.branch) > -1 ? this.props.branch[findWithAttr(this.props.branch,'ten_chi_nhanh',data.branch)].color : ''}`} onClick={() => this.props.onClick(data)}>
                                        <p className="content">{data.title}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div> : null}
            </div>
        )
    }
}

class CalendarActionButtons extends Component {
    render() {
        return(
            <div className={`calendar_action_buttons ${this.props.focus_date > -1 ? 'active' : ''}`}>
                <div className="buttons_container">
                    <Link className="button" to={`/bill_edit/create_new/${this.props.year}-${this.props.month <= 9 ? '0' + this.props.month : this.props.month}-${this.props.focus_date <= 9 ? '0' + this.props.focus_date : this.props.focus_date}`}><FiPlus className="icon"/></Link>
                </div>
            </div>
        )
    }
}

class LavenCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calendar_data: null,
            month: null,
            year: null,
            focus_date: -1
        }
    }

    componentDidMount = () => {
        this.handleUpdateCalendar(new Date().getMonth() + 1, new Date().getFullYear())
    }

    handleUpdateCalendar = (month, year) => {
        calendar(month, year, (result) => {
            this.setState({
                calendar_data: result.date,
                month: result.month,
                year: result.year
            })
        });
    }

    //Chọn 1 ngày
    handleFocusBlock = (date)  => {
        if(date != 0) {
            this.setState({
                focus_date: date
            })
        }

        let events = this.props.events.filter((data, i) => {
            let dates = data.ngay_chup.split("-");
            if(dates[2] != this.state.year) return false;
            if(dates[1] != this.state.month) return false;

            return dates[0] == date;
        })

        function compare(a,b) {
            var time1 = parseFloat(a.gio_chup.replace(':','.'));
            var time2 = parseFloat(b.gio_chup.replace(':','.'));
            if(a.gio_chup.match(/.*pm/)) time1 += 12; if(b.gio_chup.match(/.*pm/)) time2 += 12;
            if (time1 < time2) return -1;
            if (time1 > time2) return 1;
            return 0;
        }

        events.sort(compare);

        this.props.onClickDate(events);
    }

    render() {
        const events = this.props.events;
        if(!this.state.calendar_data ||!this.state.month || !this.state.year) {
            return <div></div>
        }
        return(
            <div className="calendar">
                <CalendarHeader onNavigate={this.handleUpdateCalendar}/>
                <div className="calendar_container">
                    {this.state.calendar_data.map((data, i) => {
                        const date_check = this.state.year + '-' +this.state.month + '-' + (data <= 9 ? '0' + data : data)
                        return <CalendarBlock focus_date={this.state.focus_date} month={this.state.month} year={this.state.year} onBlockClick={() => this.handleFocusBlock(data)} onClick={this.props.onClick} date={data} key={i} branch={this.props.branch} events_num={findWithAttr(events, 'ngay_chup', date_check)} events={events}/>
                    })}
                </div>
                <CalendarActionButtons focus_date={this.state.focus_date} year={this.state.year} month={this.state.month}/>
            </div>
        )
    }
}

export {
    LavenCalendar
};