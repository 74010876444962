import React, {Component} from 'react';
import Axios from 'axios';
import {API, findWithAttr} from '../functions/Global';
import {Redirect} from 'react-router-dom';
import {APIs, getStatus} from '../functions/Api';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: false,
            loginMsg: ""
        }

        getStatus((res) => {
            console.log(res);
        })
    }

    componentDidMount() {

        //CHECK IF USER LOGGED IN
        if(localStorage.getItem('user_id') !== null && localStorage.getItem('user_id') !== null) {
            this.setState({
                loginSuccess: true
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let data = {
            email: this.refs.email.value,
            password: this.refs.password.value
        }

        Axios.post(API+ '/login', {data: data}).then((response) => {
           if(response.data.msg !== "FAIL") {
                //GET PERMS
                APIs.post('/get_permissions', '', {}, (res) => {
                    console.log(response.data);
                    let chuc_vu_name = res.data[findWithAttr(res.data, '_id', response.data.chuc_vu)].ten_nhom;
                    
                    localStorage.setItem('JWT', response.data.JWT);
                    localStorage.setItem('user_id', response.data.user_id);
                    localStorage.setItem('authed', true);
                    localStorage.setItem('chuc_vu', response.data.chuc_vu);
                    localStorage.setItem('ho_ten', response.data.ho_ten);
                    localStorage.setItem('logged_time', response.data.logged_time);
                    localStorage.setItem('ten_chuc_vu', chuc_vu_name.toLowerCase());
    
                    window.location.reload()
    
                    this.setState({
                        loginSuccess: true
                    })
                })
            }else{
                this.setState({
                    loginMsg: "Sai tên người dùng hoặc mật khẩu"
                })
            }
        });
    }

    render() {
        if(this.state.loginSuccess) {
            return(
                <Redirect to='/'/>
            )
        }

        return(
            <div className="login_view">
                <h1>Đăng Nhập</h1>
                <a style={{marginBottom: "16px", fontSize: "14px", color: "red"}}>{this.state.loginMsg}</a>
                <form action="/" method="POST" className="login" onSubmit={this.handleSubmit}>
                    <input type="text" placeholder="Tên Người Dùng" ref="email" className="textbox"/>
                    <input type="password" placeholder="Mật khẩu" ref="password" className="textbox"/>
                    <button className="btn blue">Đăng nhập</button>
                </form>
            </div>
        )
    }
}

export default Login;