import React, {Component} from 'react';
import {SpendingTable} from '../components/Table';
import {DefaultHeaderBar} from '../components/Header_bar';
import {SpendingModal, SpendingEditModal} from '../components/Modals';
import {getSingleSpending, getSpending, getPermissions} from '../functions/Api';
import {removeVietnameseTones, findWithAttr} from '../functions/Global';
import {HashSpiner} from '../components/Spiners';

class Spending extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            modal_is_open: "false",
            edit_modal_is_open: "false",
            edit_id: null,
            data_spending: null,
            perms: null,
            date_edit: null
        }

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        })

        getSpending((res) => {
          this.setState ({
            data_spending: res.reverse()
          })
        })
    }

    handleCloseModal = () => {
        this.setState({
            modal_is_open: "false",
            edit_modal_is_open: "false",
            data: null
        })

        this.updateData();
    }

    handleOpenModal = () => {
        this.setState({
            modal_is_open: "true"
        })
    }

    handleOpenEditModal = (id) => {
        getSingleSpending(id, (res) => {
            this.setState ({
                data: res,
                edit_modal_is_open: "true",
                edit_id: id,
                date_edit: res.date.split("T")[0]
            })
        });
    }

    //THỰC HIỆN SEARCH
    handleSearch = (search_value) => {
        //RELOAD DATA IF SEARCH VALUE IS NULL
        if(search_value === "") {
            getSpending((res) => {
                this.setState({
                    data_spending: res.reverse()
                })            
            })
        }

        //RELOAD DATA
        getSpending((res) => {
            let data = res.reverse()
  
            //TÌM NGƯỜI CHI OR SỐ TIỀN HOẶC NỘI DUNG
            let filtered_data = data.filter((data, i) => {
                if(removeVietnameseTones(data.noi_dung).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true; 
                if(removeVietnameseTones(data.so_tien.toString()).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true;  
                if(removeVietnameseTones(data.user).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true;   
                
                return false;
            })
  
            this.setState({
                data_spending: filtered_data
            })            
        })
    }

    //MODAL EDIT SỬA NGÀY CHI
    handleChangeDate = (date) => {
        let date_trans = date.split("-");
        date_trans = date_trans[2] + "-" + date_trans[1] + "-" + date_trans[0];

        this.setState({
            date_edit: date_trans
        })
    }

    updateData = () => {
        getSpending((res) => {
        this.setState ({
            data_spending: res.reverse()
        })
      })
    }

    render() {
        if(!this.state.data_spending || !this.state.perms) {
            return(<div className="load_view"><HashSpiner/></div>)
        }

        return(
            <div>
                <DefaultHeaderBar title="Chi" handleCreate={this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.spending[1] == "1" ? this.handleOpenModal : null} onSearchKeyPress={this.handleSearch}/>
                <SpendingTable data={this.state.data_spending} ref="table" handleOpenModal={this.handleOpenEditModal}/>
                <SpendingModal is_open={this.state.modal_is_open} handleCloseModal={this.handleCloseModal}/>
                <SpendingEditModal date_edit={this.state.date_edit} onChangeDate={this.handleChangeDate} perms={this.state.perms} data={this.state.data} is_open={this.state.edit_modal_is_open} edit_id={this.state.edit_id} handleCloseModal={this.handleCloseModal}/>
            </div>
        )
    }
}

export default Spending;