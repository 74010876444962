import React, {Component} from 'react';
import {logout} from '../functions/Api';
import {Redirect} from 'react-router-dom';

class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            success: false
        }
        
        logout(localStorage.getItem('user_id'), (res) => {
            this.handleLogout();
        });

    }
    
    componentWillUnmount = () => {
        window.location.reload();
    }

    handleLogout = () => {

        localStorage.removeItem('JWT');
        localStorage.removeItem('user_id');
        localStorage.removeItem('authed');
        localStorage.removeItem('chuc_vu');
        localStorage.removeItem('ho_ten');

        this.setState({
            success: true
        })
    }

    render() {
        if(this.state.success) {
            return(
                <Redirect to='/login'/>
            )
        }

        return(
            <div></div>
        )
    }
}

export default Logout;