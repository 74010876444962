import React, {Component} from 'react';
import {getStaff, getPermissions,getGoiChup, getServices, updateSalaryHistory} from '../functions/Api';
import {DefaultHeaderBar} from '../components/Header_bar';
import {SalaryTable} from '../components/Table';
import {removeVietnameseTones, salaryLoad, salaryCalculate, numberWithCommas, findWithAttr} from '../functions/Global';
import {HashSpiner} from '../components/Spiners';

class Salary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            staff_data: null,
            date_filter: new Date().getFullYear() + '-' + parseInt(new Date().getMonth()),
            perms: null,
            salary_data: [],
            default_salary_data: []
        }

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        });

        getStaff((res) => {
            this.setState ({
              staff_data: res
            })

            let staff_data = res;

            staff_data.forEach((data ,i ) => {
                salaryCalculate(data, new Date().getFullYear(), parseInt(new Date().getMonth()), (salary_calc_data) => {
                    let salary_data = this.state.salary_data;
                    salary_data.push(salary_calc_data);

                    this.setState({
                        salary_data: salary_data,
                        default_salary_data: salary_data
                    })

                    this.handleLoadBonus();
                })
            })
        });
    }

    //SEARCH
    handleSearch = (search_value) => {
        //RELOAD DATA IF SEARCH VALUE IS NULL
        if(search_value === "") {
            this.setState({
                salary_data: this.state.default_salary_data
            })
        }

        let data = this.state.default_salary_data;
  
        //FIND STAFF NAME
        let filtered_data = data.filter((data, i) => {
            if(removeVietnameseTones(data.email).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true;  
            if(removeVietnameseTones(data.ho_ten).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true;  
            
            return false;
        })

        this.setState({
            salary_data: filtered_data
        })  
    }
  
    //Sự kiện thay đổi giá trị % thưởng
    onInputChange = (e, element, id) => {
        let salary_data = this.state.salary_data;
        let default_salary_data = this.state.default_salary_data;
        let staff_data = this.state.staff_data;
        staff_data = staff_data[findWithAttr(staff_data, '_id', id)];

        salary_data[findWithAttr(salary_data, '_id', id)][element] = element == 'ngay_cong' ? e.target.value >= 0 ? e.target.value : 0 : parseInt(e.target.value >= 0 ? e.target.value : 0);
        default_salary_data[findWithAttr(salary_data, '_id', id)][element] = element == 'ngay_cong' ? e.target.value >= 0 ? e.target.value : 0: parseInt(e.target.value >= 0 ? e.target.value : 0);
        
        salaryCalculate(salary_data[findWithAttr(salary_data, '_id', id)], parseInt(this.state.date_filter.split("-")[0]), parseInt(this.state.date_filter.split("-")[1]), (salary_calc_data) => {
            salary_data[findWithAttr(salary_data, '_id', id)] = salary_calc_data;
            default_salary_data[findWithAttr(salary_data, '_id', id)] = salary_calc_data;

            this.setState({
                salary_data: salary_data,
                default_salary_data: default_salary_data
            })

            this.handleLoadBonus();
        })
    }
  

    handleLoadBonus = async () => {
        getGoiChup((goi_chup_data) => {
            this.setState({
                goi_chup: goi_chup_data
            })
        })
  
        getServices((dich_vu_data) => {
            this.setState({
                dich_vu: dich_vu_data
            })
        })
    }
  
    //Lọc lịch sử bảng lương
    handleFilter = (date) => {
        let staff_data = this.state.staff_data;
        let dates = date.split("-");

        this.setState({
            salary_data: []
        })
        
        staff_data.forEach((data ,i ) => {
            salaryLoad(data, dates[0], parseInt(dates[1]) - 1, (salary_calc_data) => {
                let salary_data = this.state.salary_data;
                salary_data.push(salary_calc_data);

                this.setState({
                    salary_data: salary_data,
                    default_salary_data: salary_data,
                    date_filter: date
                })

                this.handleLoadBonus();
            })
        })
    }

    //SAVE
    handleSave = () => {
        let default_salary_data = this.state.default_salary_data;

        default_salary_data.forEach((s_data, i) => {
            let data = {
                luong_co_ban: s_data.luong_co_ban,
                ngay_cong: s_data.ngay_cong,
                phan_tram_thuong_dich_vu_tu_van:s_data.phan_tram_thuong_dich_vu_tu_van ? parseInt(s_data.phan_tram_thuong_dich_vu_tu_van) : 0,
                phan_tram_thuong_goi_chup_photo:s_data.phan_tram_thuong_goi_chup_photo ? parseInt(s_data.phan_tram_thuong_goi_chup_photo) : 0,
                phan_tram_thuong_goi_chup_tu_van:s_data.phan_tram_thuong_goi_chup_tu_van ? parseInt(s_data.phan_tram_thuong_goi_chup_tu_van) : 0,  
                phan_tram_thuong_photoshop:s_data.phan_tram_thuong_photoshop ? parseInt(s_data.phan_tram_thuong_photoshop) : 0,
                date_created: this.state.date_filter
            }

            updateSalaryHistory(s_data._id, data, (res) => {
                console.log(res)
            })
        })
    }

    render() {
        if(!this.state.staff_data || !this.state.perms) {
            return(<div className="load_view"><HashSpiner/></div>)
        }

        return(
            <div>
                <DefaultHeaderBar handleSave={this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.staff_salary[2] == "1" ? this.handleSave: null} onDropdownFilterSalaryBtnClick={this.handleFilter} handleSalaryFilter={this.handleFilter} handleEdit={null} onGoBack={() => this.props.history.goBack()} handleCreate={null} create_btn_link={null} onSearchKeyPress={this.handleSearch} handleRemove={null} title="Lương Nhân Viên" back_link="/settings"/>
                <SalaryTable data={this.state.salary_data} perms={this.state.perms} onInputChange={this.onInputChange}/>
            </div>
        )
    }
}

export default Salary;